import clsx from "clsx";

import { View } from "./View";
import { forwardRef, useEffect, useRef, useState } from "react";

type InputProps = {
  className?: string;
  value?: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
};

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, value, isDisabled, onChange = () => {}, onFocus = () => {} }, ref) => {
  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <input
      ref={ref}
      className={clsx("w-12 h-12 text-3xl text-center border border-gray-300 rounded-md outline-secondary", className)}
      type="text"
      value={value}
      onChange={onChangeInternal}
      onFocus={onFocus}
      disabled={isDisabled}
    />
  );
});

type CodeInputFieldProps = {
  value?: string;
  className?: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
};

export const CodeInputField = ({ className, value, isDisabled, onChange = () => {} }: CodeInputFieldProps) => {
  const [values, setValues] = useState(["", "", "", "", "", ""]);

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const onChangeHandler = (index: number) => (value: string) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });

    if (index < 5) {
      inputRefs[index + 1].current?.focus();
    } else {
      inputRefs[index].current?.blur();
    }
  };

  const onFocusHandler = (index: number) => () => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = "";
      return newValues;
    });
  };

  useEffect(() => {
    if (values.every((value) => value.length > 0)) {
      onChange(values.join(""));
    }
  }, [values]);

  useEffect(() => {
    if (value) {
      setValues(value.split("").slice(0, 6).concat(Array(6).fill("")).slice(0, 6));
    }
  }, [value]);

  useEffect(() => {
    inputRefs[0].current?.focus();
  }, []);

  return (
    <View className={clsx("flex items-center justify-center", className)}>
      {values.map((value, index) => (
        <Input
          key={index}
          className="m-1"
          onChange={onChangeHandler(index)}
          onFocus={onFocusHandler(index)}
          value={value}
          ref={inputRefs[index]}
          isDisabled={isDisabled}
        />
      ))}
    </View>
  );
};
