import clsx from "clsx";
import React, { useRef } from "react";
import { useApiRequest } from "../hooks/useApiRequest";

type ImageFieldProps = {
  className?: string;
  onChange?: (fileUrl: string) => void;
  children: React.ReactNode;
  name?: string;
};

export const ImageField = ({ className, onChange = () => {}, children }: ImageFieldProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const apiRequest = useApiRequest<{ data: string }>();

  const onClickUpload = () => {
    fileInputRef.current?.click();
  };

  const onChangeInternal = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const response = await apiRequest.fetch("/v1/files/images", {
        method: "POST",
        file,
      });

      onChange(response.data?.data || "");
    }
  };

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<HTMLButtonElement>(child)) {
          return React.cloneElement(child, {
            // @ts-ignore
            onClick: onClickUpload,
            isLoading: apiRequest.isLoading,
          });
        }

        return child;
      })}
      <input
        type="file"
        accept="image/*"
        className={clsx("rounded-full w-24 h-24 object-cover hidden", className)}
        onChange={onChangeInternal}
        ref={fileInputRef}
      />
    </>
  );
};
