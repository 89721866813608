export const parseJson = <T>(data: string): T | null => {
  let parsedJson = null;

  try {
    parsedJson = JSON.parse(data) as T;
  } catch (error) {
    // do nothing
  }

  return parsedJson;
};
