import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { View } from "../components/View";
import { Text } from "../components/Text";
import ReactConfetti from "react-confetti";
import { set } from "date-fns";

type OnboardingCompletePageProps = {
  className?: string;
};

export const OnboardingCompletePage = ({ className }: OnboardingCompletePageProps) => {
  const navigate = useNavigate();
  const [animationStage, setAnimationStage] = useState("start");
  const [fadeInStart, setFadeInStart] = useState(false);
  const [fadeInCongratulations, setFadeInCongratulations] = useState(false);

  useEffect(() => {
    setAnimationStage("start");

    requestAnimationFrame(() => {
      setFadeInStart(true);
    });

    setTimeout(() => {
      setAnimationStage("congratulations");

      requestAnimationFrame(() => {
        setFadeInCongratulations(true);
      });

      setTimeout(() => {
        setAnimationStage("welcome");

        setTimeout(() => {
          // navigate("/");
        }, 5000);
      }, 5000);
    }, 7000);
  }, []);

  return (
    <View
      className={clsx(
        "relative flex flex-col justify-between w-full h-full p-8 mx-auto bg-gradient-to-br from-primary to-secondary text-white",
        className,
      )}
    >
      {animationStage === "start" && (
        <View className="flex flex-col items-start justify-center w-full h-full">
          <Text className="text-3xl">Enhancing Maya's</Text>
          <Text className="text-3xl mb-8">Reading Journey...</Text>
          <Text
            className={clsx(
              "mb-2 transition-all delay-[1500ms] duration-1000",
              !fadeInStart ? "opacity-0 translate-y-4" : "opacity-100 translate-y-0",
            )}
          >
            📚&nbsp;&nbsp;&nbsp;Curating her library
          </Text>
          <Text
            className={clsx(
              "mb-2 transition-all delay-[3000ms] duration-1000",
              !fadeInStart ? "opacity-0 translate-y-4" : "opacity-100 translate-y-0",
            )}
          >
            🌍&nbsp;&nbsp;&nbsp;Crafting her worlds
          </Text>
          <Text
            className={clsx(
              "mb-2 transition-all delay-[4500ms] duration-1000",
              !fadeInStart ? "opacity-0 translate-y-4" : "opacity-100 translate-y-0",
            )}
          >
            📖&nbsp;&nbsp;&nbsp;Writing her stories
          </Text>
        </View>
      )}
      {animationStage === "congratulations" && (
        <View className="flex items-center justify-center w-full h-full">
          <Text
            className={clsx(
              "text-3xl opacity-0 transition-all duration-1000",
              !fadeInCongratulations ? "opacity-0 translate-y-4" : "opacity-100 translate-y-0",
            )}
          >
            Congratulations Your're in!
          </Text>
        </View>
      )}
      {animationStage === "welcome" && (
        <View className="flex items-center justify-center w-full h-full">
          <Text className="text-3xl">Welcome to Prim!</Text>
        </View>
      )}
      {(animationStage === "congratulations" || animationStage === "welcome") && (
        <ReactConfetti colors={["#F4BCEA", "#FEFEEE", "#C5B5E9", "#6DD9EE", "#C5D9E1"]} />
      )}
    </View>
  );
};
