import { useNavigate } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";
import useWebSocket from "react-use-websocket";

import { Button } from "../components/Button";
import { Character } from "../redux/reducers/characterSlice";
import { CharacterListField } from "../components/CharacterListField";
import { DropdownField } from "../components/DropdownField";
import { parseJson } from "../utils/parse";
import { Story, storyActions } from "../redux/reducers/storySlice";
import { StoryTemplate } from "../redux/reducers/storyTemplateSlice";
import { Theme } from "../redux/reducers/themeSlice";
import { useAppDispatch } from "../redux";
import { useEntity } from "../hooks/useEntity";
import { View } from "../components/View";
import { World } from "../redux/reducers/worldSlice";
import { WS_URL } from "../config";
import { StoryTemplateSet } from "../redux/reducers/storyTemplateSetSlice";
import { StorySet, storySetActions } from "../redux/reducers/storySetSlice";
import { InputField } from "../components/InputField";

type WebsocketMessage = {
  event: string;
  data: unknown;
};

type AdminStoryBuilderPageProps = {
  className?: string;
};

export const AdminStoryBuilderPage = ({ className }: AdminStoryBuilderPageProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { entities: characterEntities } = useEntity<Character>("character");
  const { entities: storyTemplateEntities } = useEntity<StoryTemplate>("storyTemplate");
  const { entities: storyTemplateSetEntities } = useEntity<StoryTemplateSet>("storyTemplateSet");
  const { entities: themeEntities } = useEntity<Theme>("theme");
  const { entities: worldEntities } = useEntity<World>("world");
  const [formData, setFormData] = useState<Partial<Story & { storyTemplateSetId: string }>>({});
  const [generationType, setGenerationType] = useState<string>("");
  const [storySetName, setStorySetName] = useState<string>("");
  const [debuggerLogs, setDebuggerLogs] = useState<string[]>([]);
  const [storyId, setStoryId] = useState<string>();

  const { sendMessage } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log("Connected to websocket");

      if (storyId) {
        sendMessage(
          JSON.stringify({
            event: "joinRoom",
            data: {
              roomId: `${storyId}:debugger`,
            },
          }),
        );
      }
    },
    onMessage: (event) => {
      const parsedMessage = parseJson<WebsocketMessage>(event.data);

      switch (parsedMessage?.event) {
        case "log":
          const parsedData = parsedMessage.data as { log: string };

          if (parsedData) {
            setDebuggerLogs((prev) => [...prev, parsedData.log]);
          }
          break;
        default:
          console.log(`Unknown event: ${parsedMessage?.event}`);
          break;
      }
    },
  });

  const onChangeFieldHandler = (fieldName: string) => (value: string) => {
    if (storyId) {
      setStoryId(undefined);
      setDebuggerLogs([]);
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const createStory = async (storyTemplateId: string, storySetId?: string) => {
    const data = await dispatch(
      storyActions.create({
        ...formData,
        storyTemplateId,
        storySetId,
        title: "Generated",
      }),
    );

    const story = data.payload as Story;

    if (story) {
      setStoryId(story.id);

      sendMessage(
        JSON.stringify({
          event: "joinRoom",
          data: {
            roomId: `${story.id}:debugger`,
          },
        }),
      );
    }
  };

  const onClickCreate = async () => {
    if (formData.storyTemplateSetId) {
      const storyTemplateSet = storyTemplateSetEntities[formData.storyTemplateSetId];

      const data = await dispatch(
        storySetActions.create({
          storyTemplateSetId: formData.storyTemplateSetId,
          name: storySetName,
        }),
      );

      const storySet = data.payload as StorySet;

      if (storyTemplateSet) {
        for (const storyTemplateId of storyTemplateSet.storyTemplateIds) {
          createStory(storyTemplateId, storySet.id);
        }
      }
    } else if (formData.storyTemplateId) {
      createStory(formData.storyTemplateId);
    }
  };

  const onClickNew = () => {
    setStoryId(undefined);
    setDebuggerLogs([]);
  };

  const onClickViewStory = () => {
    navigate(`/story?id=${storyId}`);
  };

  const onChangeCharactersHandler = (key: string) => (characterIds: string[]) => {
    if (key === "speakingCharacterIds" || key === "nonSpeakingCharacterIds") {
      setFormData({
        ...formData,
        [key]: characterIds,
      });
    }
  };

  const onChangeGenerationType = (value: string) => {
    setGenerationType(value);
    setFormData({});
  };

  const onChangeStorySetName = (value: string) => {
    setStorySetName(value);
  };

  return (
    <View className={clsx(className, "flex items-center justify-between w-[calc(100vw-200px)] h-full p-4")}>
      <View className="flex flex-col items-center justify-between h-full w-[50%] mr-4 border-[1px] border-gray-300 rounded-md">
        <View className="flex flex-col items-center justify-between w-full h-full overflow-y-scroll p-4">
          <DropdownField
            className="!h-[42px]"
            label="What would you like to generate?"
            options={[
              { label: "Story", value: "story" },
              { label: "Story Set", value: "storyset" },
            ]}
            onChange={onChangeGenerationType}
            value={generationType}
            searchable
          />
          {generationType === "storyset" && (
            <>
              <DropdownField
                className="!h-[42px]"
                label="Story Template Set"
                options={Object.values(storyTemplateSetEntities).map((storyTemplateSet) => ({
                  label: storyTemplateSet.name,
                  value: storyTemplateSet.id,
                }))}
                onChange={onChangeFieldHandler("storyTemplateSetId")}
                value={formData.storyTemplateSetId || ""}
                searchable
              />
              <InputField
                className="!h-[42px]"
                label="Story Set Name"
                onChange={onChangeStorySetName}
                value={storySetName}
              />
            </>
          )}
          {generationType === "story" && (
            <DropdownField
              className="!h-[42px]"
              label="Story Template"
              options={Object.values(storyTemplateEntities).map((storyTemplate) => ({
                label: storyTemplate.name,
                value: storyTemplate.id,
              }))}
              onChange={onChangeFieldHandler("storyTemplateId")}
              value={formData.storyTemplateId || ""}
              searchable
            />
          )}
          {generationType && (
            <>
              <DropdownField
                className="!h-[42px]"
                label="World"
                options={Object.values(worldEntities).map((world) => ({
                  label: world.name,
                  value: world.id,
                }))}
                onChange={onChangeFieldHandler("worldId")}
                value={formData.worldId || ""}
                searchable
              />
              <DropdownField
                className="!h-[42px]"
                label="Theme"
                options={Object.values(themeEntities).map((theme) => ({
                  label: theme.name,
                  value: theme.id,
                }))}
                onChange={onChangeFieldHandler("themeId")}
                value={formData.themeId || ""}
                searchable
              />
              <DropdownField
                className="!h-[42px]"
                label="Narrator"
                options={Object.values(characterEntities).map((theme) => ({
                  label: theme.name,
                  value: theme.id,
                }))}
                onChange={onChangeFieldHandler("narratorId")}
                value={formData.narratorId || ""}
                searchable
              />
              <CharacterListField
                label="Speaking Characters"
                value={formData.speakingCharacterIds || []}
                onChange={onChangeCharactersHandler("speakingCharacterIds")}
              />
              <CharacterListField
                label="Non-Speaking Characters"
                value={formData.nonSpeakingCharacterIds || []}
                onChange={onChangeCharactersHandler("nonSpeakingCharacterIds")}
              />
            </>
          )}
        </View>
        <View className="flex flex-col items-center justify-end w-full p-4">
          {storyId && (
            <Button
              type="outlined"
              className="mb-2"
              onClick={onClickViewStory}
            >
              View Story
            </Button>
          )}
          {storyId ? <Button onClick={onClickNew}>New Story</Button> : <Button onClick={onClickCreate}>Create</Button>}
        </View>
      </View>
      <View className="flex flex-col items-start justify-start w-[50%] h-full bg-gray-200 border-[1px] border-gray-300 rounded-md p-4 debugger-font overflow-y-scroll">
        {debuggerLogs.map((log, index) => (
          <>
            <span className="text-gray-700">{log}</span>
            <span className="text-gray-400 my-1">----------------------------------------</span>
          </>
        ))}
      </View>
    </View>
  );
};
