import { CircularProgress } from "@mui/material";
import clsx from "clsx";

import { View } from "./View";
import { forwardRef } from "react";

type ButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseMove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  color?: "primary" | "secondary" | "danger" | "warning" | "success" | "white";
  type?: "default" | "outlined" | "link" | "text" | "basic";
  href?: string;
  style?: React.CSSProperties;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      color = "primary",
      isDisabled,
      isLoading,
      onClick = () => {},
      onMouseDown = () => {},
      onMouseMove = () => {},
      onMouseUp = () => {},
      style,
      type = "default",
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          "relative w-full cursor-pointer active:scale-[0.99] transition-all duration-75 origin-center rounded-md",
          type !== "basic" && "p-2 text-xl",
          color === "primary" && type === "default" && "text-white bg-primary border-primary border-2 hover:bg-primary-dark hover:border-primary-dark",
          color === "primary" && type === "outlined" && "text-primary bg-white border-primary border-2 hover:bg-gray-100",
          color === "primary" && type === "link" && "text-primary bg-white",
          color === "secondary" &&
            type === "default" &&
            "text-white bg-secondary border-secondary border-2 hover:bg-secondary-dark hover:border-secondary-dark",
          color === "secondary" && type === "outlined" && "text-secondary bg-white border-secondary border-2 hover:bg-gray-100",
          color === "secondary" && type === "link" && "text-secondary bg-white",
          color === "danger" && type === "default" && "text-white bg-red-500 border-red-500 border-2 hover:bg-red-600 hover:border-red-600",
          color === "danger" && type === "outlined" && "text-red-500 bg-white border-red-500 border-2 hover:bg-gray-100",
          color === "danger" && type === "link" && "text-red-500 bg-white",
          color === "white" && type === "default" && "bg-white border-white border-2 active:bg-gray-100 active:border-gray-100",
          isDisabled && "opacity-50 cursor-default",
          className,
        )}
        style={style}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        disabled={isDisabled}
      >
        {type === "basic" ? (
          children
        ) : (
          <>
            <View className={clsx("flex items-center justify-center select-none", isLoading ? "opacity-0" : "opacity-100")}>{children}</View>
            <View className={clsx("absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-6 h-6", isLoading ? "opacity-100" : "opacity-0")}>
              <CircularProgress
                className={clsx(
                  "!w-full !h-full",
                  type === "default" && "!text-white",
                  color === "primary" && type === "outlined" && "!text-primary",
                  color === "secondary" && type === "outlined" && "!text-secondary",
                  color === "danger" && type === "outlined" && "!text-red-500",
                  color === "white" && type === "default" && "text-gray-700",
                )}
              />
            </View>
          </>
        )}
      </button>
    );
  },
);
