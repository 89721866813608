import { useNavigate } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";

import { Button } from "../components/Button";
import { SelectableCard } from "../components/SelectableCard";
import { Text } from "../components/Text";
import { View } from "../components/View";

type OnboardingAccountTypePageProps = {
  className?: string;
};

export const OnboardingAccountTypePage = ({ className }: OnboardingAccountTypePageProps) => {
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState<"owner" | "contributor">("owner");

  const onChangeFormDataHandler = (newAccountType: "owner" | "contributor") => () => {
    setAccountType(newAccountType);
  };

  const onClickCreateAccount = () => {
    navigate("/onboarding/owner");
  };

  const onClickEnterCode = () => {
    navigate("/onboarding/contributor");
  };

  return (
    <View className={clsx("relative w-full h-full flex flex-col items-center justify-center", className)}>
      <View className="flex-grow p-4">
        <View className="w-full mb-8">
          <Text className="text-3xl text-left text-gray-700">Let's get you set up for storytelling. Tell us the part you want to play. ✨</Text>
        </View>
        <SelectableCard
          className="mb-4"
          title="Owner"
          subtitle="I'm setting this account up for my child."
          isSelected={accountType === "owner"}
          onClick={onChangeFormDataHandler("owner")}
        />
        <SelectableCard
          className="mb-4"
          title="Contributor"
          subtitle="I received a link from a family member, and I want to add to the story."
          isSelected={accountType === "contributor"}
          onClick={onChangeFormDataHandler("contributor")}
        />
      </View>
      <View className="flex items-center justify-center w-full p-4">
        {accountType === "owner" ? (
          <Button onClick={onClickCreateAccount}>Create Account</Button>
        ) : accountType === "contributor" ? (
          <Button onClick={onClickEnterCode}>Enter Code</Button>
        ) : null}
      </View>
    </View>
  );
};
