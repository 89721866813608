import { createRestSlice } from "./restSlice";

export type AccountContributor = {
  id: string;
  name: string;
  description: string;
  image: string;
  accountId: string;
  createdAt: string;
  updatedAt: string;
};

const accountContributorSlice = createRestSlice<AccountContributor>("accountContributor", "/v1/accountContributors");

export const accountContributorActions = accountContributorSlice.actions;
export const accountContributorReducer = accountContributorSlice.reducer;
