import clsx from "clsx";

import { View } from "../components/View";
import { Button } from "../components/Button";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Image } from "../components/Image";
import { ArrowBackRounded, Close, CloseRounded } from "@mui/icons-material";
import { ProgressBar } from "../components/ProgressBar";
import { Text } from "../components/Text";

type OnboardingLayoutProps = {
  className?: string;
};

export const OnboardingLayout = ({ className }: OnboardingLayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const totalSteps = pathname.includes("contributor") ? 4 : 8;
  const currentStep = parseInt(searchParams.get("step") || "0");

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickClose = () => {
    navigate("/");
  };

  return (
    <View className={clsx("relative w-full h-full flex flex-col items-center justify-center transition-all", className)}>
      <Image
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://api.primstories.com/v1/files/images/822ff9a5-68ba-4de1-87d1-ab159f970e37.webp"
      />
      <View
        className={clsx(
          "relative flex flex-col items-center justify-start bg-white p-4 w-full h-full",
          "sm:h-auto sm:max-w-[400px] sm:min-h-[80vh] sm:max-h-[80vh] sm:rounded-2xl",
        )}
      >
        <View className="flex items-center justify-start w-full p-4">
          {currentStep === totalSteps ? (
            <Button
              type="basic"
              onClick={onClickClose}
              className="flex items-center justify-center mr-2 !w-6 !h-6 !p-0"
            >
              <CloseRounded className="!w-6 !h-6" />
            </Button>
          ) : (
            <Button
              type="basic"
              onClick={onClickBack}
              className="flex items-center justify-center mr-2 !w-6 !h-6 !p-0"
            >
              <ArrowBackRounded className="!w-6 !h-6 transform scale-x-125" />
            </Button>
          )}
          <ProgressBar progress={Math.round((currentStep / totalSteps) * 100)} />
          <Text className="text-sm font-light ml-2">{Math.round((currentStep / totalSteps) * 100)}%</Text>
        </View>
        <Outlet />
      </View>
    </View>
  );
};
