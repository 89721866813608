import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import clsx from "clsx";

import { View } from "../components/View";
import { Button } from "../components/Button";
import { Text } from "../components/Text";
import { SelectableCard } from "../components/SelectableCard";
import { InputField } from "../components/InputField";
import { AvatarUpload } from "../components/AvatarUpload";
import { SelectableImageCard } from "../components/SelectableImageCard";
import { World } from "../redux/reducers/worldSlice";
import { useEntity } from "../hooks/useEntity";
import { ProgressBar } from "../components/ProgressBar";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../redux";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { ErrorMessage } from "../components/ErrorMessage";
import { capitalize } from "../utils/strings";

type FormData = {
  email?: string;
  password?: string;
  confirmPassword?: string;
  accountType?: "admin" | "contributor";
  contributorAvatar?: string;
  contributorName?: string;
  readerPersonality?: string;
  contributorRelationship?: string;
  readerGender?: "boy" | "girl" | "none";
  readerName?: string;
  readerType?: "emergent" | "early" | "fluent";
  worldIds?: string[];
};

type OnboardingOwnerPageProps = {
  className?: string;
};

export const OnboardingOwnerPage = ({ className }: OnboardingOwnerPageProps) => {
  const navigate = useNavigate();
  const { entities: worldMap, ids: worldIds } = useEntity<World>("world");
  const [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState<FormData>({});
  const totalSteps = 8;
  const currentStep = parseInt(searchParams.get("step") || "0");
  const passwordStrengthRequest = useApiRequest<{ strength: string; score: number }>();
  const createAccountApiRequest = useApiRequest();
  const createAccountReaderApiRequest = useApiRequest();

  const onChangePassword = useDebouncedCallback((password) => {
    passwordStrengthRequest.fetch("/v1/auth/strength", {
      method: "POST",
      body: {
        password,
      },
    });
  }, 300);

  const onChangeFormDataHandler = (key: keyof FormData, buttonValue?: string) => (value?: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: buttonValue || value,
    }));
  };

  const onClickCreateAccount = async () => {
    const { error } = await createAccountApiRequest.fetch("/v1/auth/signup", {
      method: "POST",
      body: {
        email: formData.email,
        password: formData.password,
      },
    });

    if (!error) {
      navigate("/onboarding/owner?step=2");
    }
  };

  const onClickCreateAccountReader = async () => {
    const { error } = await createAccountApiRequest.fetch("/v1/accountReaders", {
      method: "POST",
      body: {
        name: formData.readerName,
      },
    });

    if (!error) {
      navigate("/onboarding/owner?step=2");
    }
  };

  const onClickContinue = () => {
    setSearchParams({ step: String(currentStep + 1) });

    if (currentStep + 1 >= totalSteps) {
      navigate("/onboarding/complete");
    }
  };

  const onClickWorldHandler = (id: string) => () => {
    setFormData((prev) => {
      let newWorldIds = prev.worldIds || [];

      if (prev.worldIds?.includes(id)) {
        newWorldIds = prev.worldIds.filter((prevId) => prevId !== id);
      } else {
        newWorldIds = [...newWorldIds, id];
      }

      return {
        ...prev,
        worldIds: newWorldIds,
      };
    });
  };

  useEffect(() => {
    if (!passwordStrengthRequest.isLoading && formData.password) {
      onChangePassword(formData.password);
    }
  }, [formData]);

  return (
    <View className={clsx("relative w-full h-full flex flex-col items-center justify-center", className)}>
      {currentStep === 0 && (
        <View className="flex flex-col flex-grow p-4 justify-between">
          <View className="w-full flex-col flex-grow">
            <View className="w-full mb-2">
              <Text className="text-3xl text-left text-gray-700">Let's get started!</Text>
            </View>
            <View className="w-full mb-8">
              <Text className="text-lg text-left text-gray-400 font-light">Please enter an email and password to be tied to the account.</Text>
            </View>
            <InputField
              placeholder="Email"
              className="mb-4"
              onChange={onChangeFormDataHandler("email")}
              value={formData.email}
            />
            <InputField
              placeholder="Password"
              type="password"
              className="mb-4"
              onChange={onChangeFormDataHandler("password")}
              value={formData.password}
            />
            <InputField
              placeholder="Confirm Password"
              type="password"
              className="mb-8"
              onChange={onChangeFormDataHandler("confirmPassword")}
              value={formData.confirmPassword}
            />
            <ProgressBar
              className="mb-2"
              color={passwordStrengthRequest.data?.strength === "weak" ? "red" : passwordStrengthRequest.data?.strength === "medium" ? "yellow" : "green"}
              progress={(passwordStrengthRequest.data?.score || 0) * 25}
            />
            <View className="flex flex-col h-12">
              {!passwordStrengthRequest.data?.strength ? (
                <Text className="text-gray-400">Password must be at least 10 characters long and include at least one number or one symbol.</Text>
              ) : (
                <Text
                  className={clsx(
                    passwordStrengthRequest.data?.strength === "weak" && "text-rose-500",
                    passwordStrengthRequest.data?.strength === "medium" && "text-yellow-600",
                    passwordStrengthRequest.data?.strength === "strong" && "text-secondary",
                  )}
                >
                  {capitalize(passwordStrengthRequest.data?.strength)} Password
                </Text>
              )}
              {formData.confirmPassword && formData.password !== formData.confirmPassword && <Text className="text-rose-500">Passwords don't match</Text>}
            </View>
          </View>
          <ErrorMessage
            className="my-4"
            text={createAccountApiRequest.error?.message}
          />
          <Button
            className="self-end"
            onClick={onClickCreateAccount}
            isDisabled={
              !formData.email ||
              !formData.password ||
              !formData.confirmPassword ||
              formData.password !== formData.confirmPassword ||
              passwordStrengthRequest.data?.strength !== "strong"
            }
            isLoading={createAccountApiRequest.isLoading}
          >
            Create Account
          </Button>
        </View>
      )}
      {currentStep === 1 && (
        <View className="flex flex-col flex-grow p-4 justify-between">
          <View className="w-full flex-col flex-grow">
            <View className="w-full mb-2">
              <Text className="text-3xl text-left text-gray-700">What's your little one's name?</Text>
            </View>
            <View className="w-full mb-8">
              <Text className="text-lg text-left text-gray-400 font-light">Stories will feature your child's name.</Text>
            </View>
            <InputField
              placeholder="Enter first name"
              onChange={onChangeFormDataHandler("readerName")}
              value={formData.readerName}
            />
          </View>
          <ErrorMessage
            className="my-4"
            text={createAccountApiRequest.error?.message}
          />
          <Button
            className="self-end"
            onClick={onClickCreateAccountReader}
            isDisabled={!formData.readerName}
            isLoading={createAccountReaderApiRequest.isLoading}
          >
            Continue
          </Button>
        </View>
      )}
      {currentStep === 2 && (
        <View className="flex-grow p-4">
          <View className="w-full mb-2">
            <Text className="text-3xl text-left text-gray-700">
              What does <Text className="text-secondary">{formData.readerName}</Text> call you?
            </Text>
          </View>
          <View className="w-full mb-8">
            <Text className="text-lg text-left text-gray-400 font-light">Your name will be used in stories too!</Text>
          </View>
          <InputField
            className="mb-4"
            placeholder="Enter nickname"
            onChange={onChangeFormDataHandler("contributorName")}
            value={formData.contributorName}
          />
          <InputField
            placeholder={`Enter relationship to ${formData.readerName}`}
            onChange={onChangeFormDataHandler("contributorRelationship")}
            value={formData.contributorRelationship}
          />
        </View>
      )}
      {currentStep === 3 && (
        <View className="flex-grow p-4">
          <View className="w-full mb-2">
            <Text className="text-3xl text-left text-gray-700">Tell us about your reader. What's their gender?</Text>
          </View>
          <View className="w-full mb-8">
            <Text className="text-lg text-left text-gray-400 font-light">
              This ensures each tale is uniquely tailored, engaging, and delightful, making story time a magical experience.
            </Text>
          </View>
          <SelectableCard
            className="mb-4"
            title="Boy"
            subtitle="(he/him)"
            isSelected={formData.readerGender === "boy"}
            onClick={onChangeFormDataHandler("readerGender", "boy")}
          />
          <SelectableCard
            className="mb-4"
            title="Girl"
            subtitle="(she/her)"
            isSelected={formData.readerGender === "girl"}
            onClick={onChangeFormDataHandler("readerGender", "girl")}
          />
          <SelectableCard
            title="Do not gender"
            subtitle="(always use name)"
            isSelected={formData.readerGender === "none"}
            onClick={onChangeFormDataHandler("readerGender", "none")}
          />
        </View>
      )}
      {currentStep === 4 && (
        <View className="flex-grow p-4">
          <View className="w-full mb-8">
            <Text className="text-3xl text-left text-gray-700">To tailor the experience for your child, select their current reading level. 📚</Text>
          </View>
          <SelectableCard
            className="mb-4"
            title="Emergent Reader"
            subtitle="Prefers books with lots of pictures and simple words."
            isSelected={formData.readerType === "emergent"}
            onClick={onChangeFormDataHandler("readerType", "emergent")}
          />
          <SelectableCard
            className="mb-4"
            title="Early Reader"
            subtitle="Enjoys short sentences and simple stories with basic vocabulary."
            isSelected={formData.readerType === "early"}
            onClick={onChangeFormDataHandler("readerType", "early")}
          />
          <SelectableCard
            title="Fluent Reader"
            subtitle="Loves reading chapter books and longer stories."
            isSelected={formData.readerType === "fluent"}
            onClick={onChangeFormDataHandler("readerType", "fluent")}
          />
        </View>
      )}
      {currentStep === 5 && (
        <View className="flex flex-col flex-grow p-4">
          <Text className="text-3xl text-left text-gray-700">Add a photo to personalize the experience. You're a key character in this story! 📸✨</Text>
          <View className="flex flex-col items-center justify-center flex-grow">
            <AvatarUpload
              className="mx-auto my-8"
              onChange={onChangeFormDataHandler("contributorAvatar")}
              value={formData.contributorAvatar}
            />
            <Button
              color="primary"
              type="link"
              onClick={onClickContinue}
            >
              Skip the photo for now
            </Button>
          </View>
        </View>
      )}
      {currentStep === 6 && (
        <View className="flex flex-col flex-grow justify-between overflow-hidden">
          <View className="w-full mb-2 px-4">
            <Text className="text-3xl text-left text-gray-700">
              Which worlds would <Text className="text-secondary">{formData.readerName || "they"}</Text> love to explore? 🌍
            </Text>
          </View>
          <View className="w-full mb-8 px-4">
            <Text className="text-lg text-left text-gray-400 font-light">
              Explore enchanting realms, magical adventures, and exciting tales tailored just for them.
            </Text>
          </View>
          <View className="flex flex-grow flex-wrap justify-between px-4 overflow-y-scroll">
            {worldIds.map((id, index) => (
              <SelectableImageCard
                image={worldMap[id]?.image}
                isSelected={formData.worldIds?.includes(id)}
                onClick={onClickWorldHandler(id)}
                className={
                  index % 6 === 0 && index !== worldIds.length - 1
                    ? "mb-2 w-[33%]"
                    : index % 6 === 1
                      ? "mb-2 w-[65%]"
                      : index % 6 === 3 && index !== worldIds.length - 1
                        ? "mb-2 w-[65%]"
                        : index % 6 === 4
                          ? "mb-2 w-[33%]"
                          : "mb-2"
                }
              />
            ))}
          </View>
        </View>
      )}
      {currentStep === 7 && (
        <View className="flex flex-col flex-grow justify-start overflow-hidden p-4">
          <View className="w-full mb-8">
            <Text className="text-3xl text-left text-gray-700">
              Tell us about {formData.readerName ? <Text className="text-secondary">{formData.readerName}'s</Text> : "their"} personality. Let's bring their
              unique traits into the stroy! 😊
            </Text>
          </View>
          <InputField
            type="textarea"
            placeholder={`Write a brief description about ${formData.readerName || "your child"}`}
            onChange={onChangeFormDataHandler("readerPersonality")}
            value={formData.readerPersonality}
            className="!h-[30vh]"
          />
        </View>
      )}
    </View>
  );
};
