import { RestList } from "../components/RestList";

type AdminAccountReadersPageProps = {
  className?: string;
};

export const AdminAccountReadersPage = ({ className }: AdminAccountReadersPageProps) => {
  return (
    <RestList
      className={className}
      entityName="accountReader"
      fields={[
        {
          name: "name",
          label: "Name",
        },
        {
          name: "accountId",
          label: "Account Id",
        },
      ]}
    />
  );
};
