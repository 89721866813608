import { useState } from "react";

import { Button } from "../components/base/Button";
import { InputField } from "../components/base/InputField";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { Form } from "../components/base/Form";
import { contentActions } from "../redux/reducers/contentSlice";
import { OnboardingCard } from "../components/OnboardingCard";
import { Haptics } from "../utils/haptics";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "../components/View";
import { Image } from "../components/Image";

type FormData = {
  email?: string;
  password?: string;
};

type SignInPageProps = {};

export const SignInPage = ({}: SignInPageProps) => {
  const navigation = useNavigation();
  const [formData, setFormData] = useState<FormData>({});
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.session.isLoading || state.content.isLoading);
  const error = useAppSelector((state) => state.session.error);

  const onChangeFormData = (newFormData: FormData) => {
    setFormData(newFormData);
  };

  const onClickSignIn = async () => {
    Haptics.trigger(Haptics.HapticFeedbackTypes.impactLight);

    try {
      await dispatch(sessionActions.signIn(formData)).unwrap();
      await dispatch(contentActions.getContent()).unwrap();

      navigation.navigate("AudioLibraryNarrator");
    } catch (error) {
      // Unauthorized
    }
  };

  const onClickSignUp = () => {
    navigation.navigate("OnboardingAccountType");
  };

  const onClickForgotPassword = () => {
    navigation.navigate("ForgotPassword");
  };

  return (
    <View className="relative flex items-center justify-center w-full h-full">
      <Image
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://api.primstories.com/v1/files/images/822ff9a5-68ba-4de1-87d1-ab159f970e37.webp"
      />
      <OnboardingCard>
        <Text className="text-4xl md:text-6xl font-semibold text-center mb-2 text-primary">Prim Stories</Text>
        <Text className="text-lg md:text-xl font-light text-center mb-4">Inspire Readers. Connect Families.</Text>
        <Form
          data={formData}
          onChange={onChangeFormData}
        >
          <InputField
            className="mb-4"
            name="email"
            placeholder="Email"
            showError={!!error}
          />
          <InputField
            className="mb-4"
            name="password"
            placeholder="Password"
            type="password"
            showError={!!error}
          />
        </Form>
        <Button
          className="mb-4"
          isLoading={isLoading}
          onClick={onClickSignIn}
        >
          Sign In
        </Button>
        <Button
          className="mb-4"
          onClick={onClickSignUp}
          color="white"
        >
          Sign Up
        </Button>
        <Button
          className="flex flex-row justify-center"
          onClick={onClickForgotPassword}
          type="basic"
          color="white"
        >
          <Text>Forgot Password?</Text>
        </Button>
      </OnboardingCard>
    </View>
  );
};
