import { ArrowBackRounded, ErrorOutlineOutlined, MicOutlined, MicRounded } from "@mui/icons-material";
import clsx from "clsx";

import { View } from "../components/View";
import { Button } from "../components/Button";
import { Text } from "../components/Text";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProgressBar } from "../components/ProgressBar";
import { CodeInputField } from "../components/CodeInputField";
import { useApiRequest } from "../hooks/useApiRequest";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { ErrorMessage } from "../components/ErrorMessage";
import { AccountContributor } from "../redux/reducers/accountContributorSlice";
import { InputField } from "../components/InputField";
import { Form } from "../components/Form";
import { AvatarUpload } from "../components/AvatarUpload";
import ReactConfetti from "react-confetti";

type OnboardingContributorPageProps = {
  className?: string;
};

export const OnboardingContributorPage = ({ className }: OnboardingContributorPageProps) => {
  const navigate = useNavigate();
  const contributorCodeRequest = useApiRequest<{ data: AccountContributor }>();
  const [searchParams] = useSearchParams();
  const [currentCode, setCurrentCode] = useState<string>("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const currentStep = Number(searchParams.get("step"));

  const onChangeCode = async (value?: string) => {
    if (value?.length === 6) {
      const { data } = await contributorCodeRequest.fetch("/v1/accountContributors/claim", {
        method: "POST",
        body: {
          code: value,
        },
      });

      if (data?.data?.id) {
        navigate("/onboarding/contributor?step=1");
      }
    }
  };

  const onClickContinue = () => {
    navigate(`/onboarding/contributor?step=${currentStep + 1}`);
  };

  useEffect(() => {
    const code = searchParams.get("code");

    if (code) {
      setTimeout(() => {
        setCurrentCode(code);
      }, 700);
    }
  }, [searchParams]);

  const onClickMic = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        const chunks: BlobPart[] = [];

        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) chunks.push(e.data);
        };

        mediaRecorder.onstop = () => {
          const blob = new Blob(chunks, { type: "audio/webm" });
          setAudioBlob(blob);
        };

        mediaRecorder.start();
        setIsRecording(true);

        const intervalId = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);

        const timeoutId = setTimeout(() => {
          mediaRecorder.stop();
          stream.getTracks().forEach((track) => track.stop());
          setIsRecording(false);
          clearInterval(intervalId);
          setRecordingTime(0);
        }, 62000);

        setTimer(timeoutId);
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    } else {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setIsRecording(false);
      setRecordingTime(0);
    }
  };

  useEffect(() => {
    if (audioBlob) {
      console.log(audioBlob);
    }
  }, [audioBlob]);

  useEffect(() => {
    if (currentStep === 3) {
      setIsRecording(false);
      setRecordingTime(0);
    }
  }, [currentStep]);

  return (
    <View className={clsx("relative flex flex-col justify-between w-full h-full p-4 pt-0 max-w-[768px] mx-auto", className)}>
      {currentStep === 0 && (
        <View className="flex-grow">
          <View className="w-full flex items-center justify-center mb-2">
            <Text className="text-3xl text-center text-gray-700">Now let's set up your account</Text>
          </View>
          <View className="w-full flex items-center justify-center mb-8">
            <Text className="text-lg text-center text-gray-400 font-light">Enter the code that you found in your email or text message</Text>
          </View>
          <CodeInputField
            className="mb-4"
            onChange={onChangeCode}
            value={currentCode}
            isDisabled={contributorCodeRequest.isLoading}
          />
          {contributorCodeRequest.error && <ErrorMessage text={contributorCodeRequest.error.message} />}
        </View>
      )}
      {currentStep === 1 && (
        <View className="flex flex-col flex-grow justify-between">
          <View className="flex flex-col flex-grow">
            <View className="w-full mb-2">
              <Text className="text-3xl text-left text-gray-700">
                What does <Text className="text-secondary">the reader</Text> call you?
              </Text>
            </View>
            <View className="w-full mb-8">
              <Text className="text-lg text-left text-gray-400 font-light">Your name will be used in stories too!</Text>
            </View>
            <Form
              data={{
                contributorName: "",
                contributorRelationship: "",
              }}
              onChange={(formData) => console.log(formData)}
            >
              <InputField
                className="mb-4"
                placeholder="Enter nickname"
              />
              <InputField placeholder={`Enter relationship to the reader`} />
            </Form>
          </View>
          <Button
            color="primary"
            onClick={onClickContinue}
          >
            Continue
          </Button>
        </View>
      )}
      {currentStep === 2 && (
        <View className="flex flex-col flex-grow justify-between">
          <View className="flex flex-col flex-grow">
            <Text className="text-3xl text-left text-gray-700">Add a photo to personalize the experience. You're a key character in this story! 📸✨</Text>
            <View className="flex flex-col items-center justify-center flex-grow">
              <AvatarUpload
                className="mx-auto my-8"
                onChange={() => {}}
                value={""}
              />
              <Button
                color="primary"
                type="link"
                onClick={onClickContinue}
              >
                Skip the photo for now
              </Button>
            </View>
          </View>
          <Button
            color="primary"
            onClick={onClickContinue}
          >
            Continue
          </Button>
        </View>
      )}
      {currentStep === 3 && (
        <View className="flex flex-col flex-grow justify-between">
          {isRecording || audioBlob ? (
            <>
              <Text className="text-xl text-center text-secondary mb-2">Read and scroll at your own pace:</Text>
              <View className="flex flex-col max-h-[400px] overflow-y-scroll mb-4 no-scrollbar">
                <Text className="indent-8 text-lg text-left text-gray-700">
                  Once upon a sunny afternoon, a curious fox decided to take a break from exploring and wandered into a nearby park. As it strolled along the
                  winding path, the sweet scent of blooming flowers filled the air, and cheerful sounds of laughter echoed from a nearby playground.
                </Text>
                <Text className="indent-8 text-lg text-left text-gray-700">
                  Feeling adventurous, the fox veered off the trail and stumbled upon a lively gathering of animals surrounding a friendly golden retriever. The
                  dog was wagging its tail furiously, inviting everyone to join in a game of fetch. With a playful grin, the fox leaped into action, tossing a
                  frisbee that soared high into the sky.
                </Text>
                <Text className="indent-8 text-lg text-left text-gray-700">
                  The retriever bounded after it, his joyful leaps making all the animals laugh. As the sun began to set, casting a golden hue over the park,
                  the fox felt a sense of connection with the playful group. They chatted about their favorite spots in the park, sharing stories of their
                  adventures.
                </Text>
                <Text className="indent-8 text-lg text-left text-gray-700">
                  Suddenly, a little rabbit hopped over, holding a beautifully painted rock. “This is for you!” it said, eyes sparkling. The rock was colorful,
                  with a heart and the word “Joy” written on it. The fox's heart warmed at the kind gesture.
                </Text>
                <Text className="indent-8 text-lg text-left text-gray-700">
                  When it was time to leave, the fox felt lighter, the worries of the day melting away. With a new friend in the retriever and a special rock in
                  paw, it promised to return. Sometimes, a little sunshine and spontaneity could turn an ordinary day into a delightful memory, and from that
                  day on, the park became a beloved spot, where joy and laughter bloomed like the flowers around them.
                </Text>
              </View>
              <Button
                color="primary"
                onClick={onClickContinue}
                isDisabled={!audioBlob}
              >
                {recordingTime > 60 ? "Continue" : `${Math.max(0, 60 - recordingTime)} seconds remaining`}
              </Button>
            </>
          ) : (
            <>
              <Text className="text-3xl text-left text-gray-700">
                Finally let's record your voice as you read a short story so your reader can hear your voice in the future! 🗣️📚
              </Text>
              <View className="flex flex-col items-center justify-center flex-grow">
                <Button
                  className="!w-48 !h-48 !rounded-full"
                  onClick={onClickMic}
                >
                  <MicOutlined className="!h-24 !w-24 text-white" />
                </Button>
                <Text className="mt-4 text-lg text-gray-600">Tap to start recording</Text>
              </View>
            </>
          )}
        </View>
      )}
      {currentStep === 4 && (
        <View className="flex flex-col flex-grow items-center justify-center">
          <Text className="text-3xl text-center text-gray-700">You're all set! 🎉</Text>
          <Text className="text-lg text-center text-gray-400 font-light">Thank you for your time and support!</Text>
          <Text className="text-lg text-center text-gray-400 font-light">Your readers will be thrilled to hear your voice in the story.</Text>
        </View>
      )}
    </View>
  );
};
