import { AddCircleOutline, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import clsx from "clsx";

import { AccountInfoCard } from "../components/AccountReaderCard";
import { Avatar } from "../components/Avatar";
import { AvatarField } from "../components/AvatarField";
import { Button } from "../components/Button";
import { Form } from "../components/Form";
import { InputField } from "../components/InputField";
import { Modal } from "../components/Modal";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/Text";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "../components/View";
import { AccountContributor } from "../redux/reducers/accountContributorSlice";
import { ReaderInfoModal } from "../components/ReaderInfoModal";
import { Card } from "../components/Card";
import { User } from "../redux/reducers/userSlice";
import { Image } from "../components/Image";

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const [isReaderModalOpen, setIsReaderModalOpen] = useState(false);
  const [isContributorModalOpen, setIsContributorModalOpen] = useState(false);
  const [currentReaderId, setCurrentReaderId] = useState<string>("");
  const [currentContributorId, setCurrentContributorId] = useState<string>("");
  const [contributorFormData, setContributorFormData] = useState<Partial<AccountContributor>>({});
  const [userFormData, setUserFormData] = useState<Partial<User>>({});
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const contributorApiRequest = useApiRequest();
  const userApiRequest = useApiRequest();
  const deleteApiRequest = useApiRequest();

  const onChangeContributorFormData = (formData: Record<string, unknown>) => {
    setContributorFormData(formData);
  };

  const onClickAddReader = () => {
    setIsReaderModalOpen(true);
  };

  const onClickInviteContributor = () => {
    setIsContributorModalOpen(true);
  };

  const onCloseReaderModal = () => {
    setCurrentReaderId("");
    setIsReaderModalOpen(false);
  };

  const onCloseContributorModal = () => {
    setIsContributorModalOpen(false);
    setContributorFormData({});
  };

  const onConfirmContributorModal = async () => {
    if (contributorFormData.id) {
      await contributorApiRequest.fetch(`/v1/accountContributors/${contributorFormData.id}`, {
        method: "PATCH",
        body: {
          ...contributorFormData,
          accountId: user?.account?.id,
          id: undefined,
        },
      });
    } else {
      await contributorApiRequest.fetch("/v1/accountContributors", {
        method: "POST",
        body: {
          ...contributorFormData,
          accountId: user?.account?.id,
        },
      });
    }

    dispatch(sessionActions.getSession());
    setIsContributorModalOpen(false);
  };

  const onClickDeleteReaderHandler = (readerId: string) => async () => {
    await deleteApiRequest.fetch(`/v1/accountReaders/${readerId}`, {
      method: "DELETE",
    });

    dispatch(sessionActions.getSession());
  };

  const onDeleteContributor = async () => {
    onClickDeleteContributorHandler(contributorFormData.id || "");
    onCloseContributorModal();
  };

  const onClickDeleteContributorHandler = (contributorId: string) => async () => {
    await deleteApiRequest.fetch(`/v1/accountContributors/${contributorId}`, {
      method: "DELETE",
    });

    dispatch(sessionActions.getSession());
  };

  const onClickEditReaderHandler = (readerId: string) => () => {
    setCurrentReaderId(readerId);
    setIsReaderModalOpen(true);
  };

  const onClickEditContributorHandler = (contributorId: string) => () => {
    setCurrentContributorId(contributorId);
    setIsContributorModalOpen(true);
  };

  const onChangeUserFormData = (formData: Record<string, unknown>) => {
    setUserFormData(formData);
  };

  const onConfirmUserModal = async () => {
    await userApiRequest.fetch(`/v1/users/${user?.id}`, {
      method: "PATCH",
      body: {
        ...userFormData,
        id: undefined,
      },
    });

    dispatch(sessionActions.getSession());
  };

  const onCloseUserModal = () => {
    setIsUserModalOpen(false);
    setUserFormData({});
  };

  const onClickEditUser = () => {
    setUserFormData({
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      image: user?.image,
    });

    setIsUserModalOpen(true);
  };

  useEffect(() => {
    if (currentContributorId) {
      setContributorFormData(user?.account?.contributors?.find((contributor) => contributor.id === currentContributorId) || {});
    }
  }, [currentContributorId]);

  return (
    <View className="relative flex flex-col gap-4 items-center w-full h-full p-4 overflow-y-auto bg-secondary">
      {/* <Image
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="https://api.primstories.com/v1/files/images/826f97dc-8c4f-4f2d-81e7-65804388395e.webp"
      /> */}
      <Card className="w-full mt-14 px-4 pt-2 pb-4 sm:px-8 sm:py-6">
        <View className="flex flex-row justify-between items-center w-full mb-4">
          <Text className="text-2xl font-bold">Account Information</Text>
          <Button
            onClick={onClickEditUser}
            type="basic"
            className="!w-auto p-2 flex flex-row items-center gap-1"
          >
            <Edit className="text-primary" />
            <Text className="text-primary">Edit</Text>
          </Button>
        </View>
        <View className="flex flex-col sm:flex-row gap-4 sm:gap-8">
          <View className="sm:w-auto flex flex-col items-center sm:justify-between gap-2">
            <Image
              className="h-[120px] w-[120px] sm:h-full sm:w-auto aspect-square rounded-full"
              src={user?.image}
            />
          </View>
          <View className="w-full flex justify-between gap-4">
            <View className="flex flex-col justify-between gap-2">
              <Text className="text-xl font-semibold">Name</Text>
              <Text className="text-xl font-semibold">Email</Text>
              <Text className="text-xl font-semibold">Subscription</Text>
            </View>
            <View className="flex flex-col justify-between gap-2 text-right sm:text-left sm:w-full">
              <Text>{user?.name}</Text>
              <Text>{user?.email}</Text>
              <Text>None</Text>
            </View>
          </View>
        </View>
      </Card>
      <View className="w-full flex flex-grow flex-col lg:flex-row gap-4">
        <Card className="w-full px-4 pt-2 pb-4 sm:px-8 sm:py-6">
          <View className="flex flex-row justify-between items-center w-full mb-4">
            <Text className="text-2xl font-bold">Readers</Text>
            <Button
              onClick={onClickAddReader}
              type="basic"
              className="!w-auto flex flex-row items-center gap-1"
            >
              <AddCircleOutline className="text-primary" />
              <Text className="text-primary">Add New</Text>
            </Button>
          </View>
          <View className="flex flex-col gap-2">
            {user?.account?.readers?.length ? (
              <>
                {[...user?.account?.readers]
                  .sort((a, b) => {
                    return (a.name || "").localeCompare(b.name || "");
                  })
                  .map((reader) => (
                    <AccountInfoCard
                      name={reader.name}
                      image={reader.image}
                      onClickDelete={onClickDeleteReaderHandler(reader.id)}
                      onClickEdit={onClickEditReaderHandler(reader.id)}
                    />
                  ))}
              </>
            ) : (
              <View className="flex flex-grow items-center justify-center">
                <Text className="mb-6 text-gray-500">No readers added yet.</Text>
              </View>
            )}
          </View>
        </Card>
        <Card className="w-full px-4 pt-2 pb-4 sm:px-8 sm:py-6">
          <View className="flex flex-row justify-between items-center w-full mb-4">
            <Text className="text-2xl font-bold">Contributors</Text>
            <Button
              onClick={onClickInviteContributor}
              type="basic"
              className="!w-auto flex flex-row items-center gap-1"
            >
              <AddCircleOutline className="text-primary" />
              <Text className="text-primary">Invite</Text>
            </Button>
          </View>
          {user?.account?.contributors?.length ? (
            <>
              {[...user?.account?.contributors]
                .sort((a, b) => {
                  return (a.name || "").localeCompare(b.name || "");
                })
                .map((contributor) => (
                  <AccountInfoCard
                    name={contributor.name}
                    image={contributor.image}
                    onClickDelete={onClickDeleteContributorHandler(contributor.id)}
                    onClickEdit={onClickEditContributorHandler(contributor.id)}
                  />
                ))}
            </>
          ) : (
            <View className="flex flex-grow items-center justify-center">
              <Text className="mb-6 text-gray-500">No contributors added yet.</Text>
            </View>
          )}
        </Card>
      </View>
      <ReaderInfoModal
        isOpen={isReaderModalOpen}
        onClose={onCloseReaderModal}
        readerId={currentReaderId}
      />
      <Modal
        isOpen={isContributorModalOpen}
        onClose={onCloseContributorModal}
        onConfirm={onConfirmContributorModal}
        onConfirmLoading={contributorApiRequest.isLoading}
        onDelete={onDeleteContributor}
        onDeleteLoading={deleteApiRequest.isLoading}
        title="Invite Contributor"
        className="flex flex-col flex-grow"
      >
        <Form
          data={contributorFormData}
          onChange={onChangeContributorFormData}
          className="flex flex-col flex-grow items-center"
        >
          <InputField
            label="Name"
            name="name"
            className="w-full"
            placeholder="Contributor's name"
          />
          <InputField
            label="Email"
            name="email"
            className="w-full"
            placeholder="Contributor's email"
          />
          <InputField
            label="Description"
            name="description"
            className="flex flex-grow w-full"
            labelClassName="flex flex-grow !mb-0"
            placeholder="Brief description of the contributor"
            type="textarea"
          />
        </Form>
      </Modal>
      <Modal
        isOpen={isUserModalOpen}
        onClose={onCloseUserModal}
        onConfirm={onConfirmUserModal}
        onConfirmLoading={userApiRequest.isLoading}
        title="Update Account Information"
        className="flex flex-col flex-grow"
      >
        <Form
          data={userFormData}
          onChange={onChangeUserFormData}
          className="flex flex-col flex-grow items-center"
        >
          <AvatarField name="image" />
          <InputField
            label="Name"
            name="name"
            className="w-full"
            placeholder="Your name"
          />
          <InputField
            label="Phone Number"
            name="phone"
            className="w-full"
            placeholder="Your phone number"
          />
          <InputField
            label="Email"
            name="email"
            className="w-full"
            placeholder="Your email"
          />
        </Form>
      </Modal>
    </View>
  );
};
