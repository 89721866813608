import { useEffect, useState } from "react";
import { AddOutlined, CheckOutlined, CloseOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";

import { Button } from "../components/Button";
import { CodeField } from "../components/CodeField";
import { DropdownField } from "../components/DropdownField";
import { InputField } from "../components/InputField";
import { RestList, RestListComponentProps } from "../components/RestList";
import { useEntity } from "../hooks/useEntity";
import { useAppDispatch } from "../redux";
import { StoryTemplatePrompt, storyTemplatePromptActions } from "../redux/reducers/storyTemplatePromptSlice";
import { StoryTemplate } from "../redux/reducers/storyTemplateSlice";

type AdminStoryTemplatesPageProps = {
  className?: string;
};

const MODELS = [
  { label: "gpt-4o", value: "gpt-4o" },
  { label: "gpt-4", value: "gpt-4" },
  { label: "gpt-3.5-turbo", value: "gpt-3.5-turbo" },
  { label: "gemini-1.0-pro", value: "gemini-1.0-pro" },
  { label: "gemini-1.5-pro", value: "gemini-1.5-pro" },
  { label: "gemini-1.5-flash", value: "gemini-1.5-flash" },
];

export const AdminStoryTemplatesPage = ({ className }: AdminStoryTemplatesPageProps) => {
  return (
    <RestList<StoryTemplate>
      className={className}
      entityName="storyTemplate"
      fields={[
        {
          name: "name",
          label: "Name",
        },
        {
          name: "prompts",
          label: "Content Prompt",
          type: "component",
          visible: false,
          component: ({ formData }: RestListComponentProps<StoryTemplate>) => {
            const dispatch = useAppDispatch();
            const { entities: storyTemplatePromptMap } = useEntity<StoryTemplatePrompt>("storyTemplatePrompt");
            const [selectedId, setSelectedId] = useState<string>("");
            const [currentStoryTemplatePrompt, setCurrentStoryTemplatePrompt] = useState<Partial<StoryTemplatePrompt>>({});

            const currentStoryTemplatePrompts = Object.values(storyTemplatePromptMap)
              .filter((storyTemplatePrompt) => {
                return storyTemplatePrompt.storyTemplateId === formData?.id;
              })
              .sort((a, b) => a.index - b.index);

            const onChangeFieldHandler = (fieldName: string) => (value: string) => {
              setCurrentStoryTemplatePrompt({
                ...currentStoryTemplatePrompt,
                [fieldName]: value,
              });
            };

            const onClickClose = () => {
              setSelectedId("");
            };

            const onClickSave = () => {
              dispatch(storyTemplatePromptActions.updateById(currentStoryTemplatePrompt));
              setSelectedId("");
            };

            const onClickEditHandler = (id: string) => () => {
              setSelectedId(id);
            };

            const onClickDeleteHandler = (id: string) => () => {
              dispatch(storyTemplatePromptActions.deleteById(id));
              setSelectedId(id);
            };

            useEffect(() => {
              if (selectedId) {
                setCurrentStoryTemplatePrompt(storyTemplatePromptMap[selectedId]);
              }
            }, [selectedId]);

            const onClickAddPrompt = () => {
              dispatch(
                storyTemplatePromptActions.create({
                  index: currentStoryTemplatePrompts.length,
                  model: "gpt-4o",
                  prompt: "",
                  storyTemplateId: formData.id,
                }),
              );
            };

            return (
              <div className="flex flex-col h-full overflow-y-scroll">
                <div className="flex flex-row items-center justify-between w-full mb-2">
                  {!formData?.id || formData?.id === "new" ? (
                    <span>Save first to add prompts...</span>
                  ) : (
                    <Button
                      className="!flex !pl-0 !text-blue-500 !justify-start !text-base"
                      onClick={onClickAddPrompt}
                      type="link"
                    >
                      <AddOutlined />
                      Add Prompt
                    </Button>
                  )}
                  {selectedId && (
                    <div className="flex flex-row items-center justify-between">
                      <Button
                        type="basic"
                        className="!w-6 mr-8"
                        onClick={onClickClose}
                      >
                        <CloseOutlined className="!text-gray-700" />
                      </Button>
                      <Button
                        type="basic"
                        className="!w-6"
                        onClick={onClickSave}
                      >
                        <CheckOutlined className="!text-blue-500" />
                      </Button>
                    </div>
                  )}
                </div>
                {!selectedId ? (
                  <div className="pl-1 py-2 flex flex-col gap-2">
                    {currentStoryTemplatePrompts.map((storyTemplatePrompt) => (
                      <div className="grid grid-cols-[64px_64px_1fr_64px_32px] items-center justify-between">
                        <span>{storyTemplatePrompt?.index}</span>
                        <span>{storyTemplatePrompt?.model}</span>
                        <span className="truncate mr-16">{storyTemplatePrompt?.prompt}</span>
                        <Button
                          type="basic"
                          className="!w-[24px]"
                          onClick={onClickEditHandler(storyTemplatePrompt.id)}
                        >
                          <EditOutlined className="!text-blue-500" />
                        </Button>
                        <Button
                          type="basic"
                          className="!w-[24px]"
                          onClick={onClickDeleteHandler(storyTemplatePrompt.id)}
                        >
                          <DeleteOutline className="!text-red-500" />
                        </Button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row items-center justify-between w-full mb-2">
                      <InputField
                        value={String(currentStoryTemplatePrompt?.index)}
                        className="mr-2"
                        onChange={onChangeFieldHandler("index")}
                      />
                      <DropdownField
                        value={currentStoryTemplatePrompt?.model}
                        onChange={onChangeFieldHandler("model")}
                        options={MODELS}
                      />
                    </div>
                    <CodeField
                      className="!h-[300px] mb-2"
                      value={currentStoryTemplatePrompt?.prompt}
                      onChange={onChangeFieldHandler("prompt")}
                    />
                  </div>
                )}
              </div>
            );
          },
        },
      ]}
      editModal
    />
  );
};
