import { createSlice, createAsyncThunk, Draft } from "@reduxjs/toolkit";
import { makeRequest } from "../../utils/request";

export type Item = {
  id: string;
};

export type ItemState<T> = {
  ids: string[];
  entities: Record<string, T>;
  isLoading: boolean;
};

export type RestSliceOptions = {
  extraReducers: (builder: any) => void;
};

export const createRestSlice = <T extends Item>(name: string, endpoint: string, options?: RestSliceOptions) => {
  const get = createAsyncThunk(`${name}/get`, async (query: Record<string, string> = {}) => {
    const queryString = Object.entries(query)
      .map(([key, value]) => `filters[${key}]=${value}`)
      .join("&");
    const { data } = await makeRequest<{ data: T[] }>(`${endpoint}?${queryString}`);

    return data;
  });

  const create = createAsyncThunk(`${name}/create`, async (item: Partial<T>) => {
    const { data } = await makeRequest<{ data: T }>(endpoint, {
      method: "POST",
      body: item,
    });

    return data;
  });

  const getById = createAsyncThunk(`${name}/getById`, async (id: string) => {
    const { data } = await makeRequest<{ data: T }>(`${endpoint}/${id}`);

    return data;
  });

  const updateById = createAsyncThunk(`${name}/updateById`, async (item: Partial<T>) => {
    const id = item.id;

    delete item.id;

    const { data } = await makeRequest<{ data: T }>(`${endpoint}/${id}`, {
      method: "PATCH",
      body: item,
    });

    return data;
  });

  const updateLocalById = createAsyncThunk(`${name}/updateLocalById`, async (item: Partial<T>) => {
    return item as T;
  });

  const deleteById = createAsyncThunk(`${name}/deleteById`, async (id: string) => {
    await makeRequest(`${endpoint}/${id}`, {
      method: "DELETE",
    });

    return id;
  });

  const initialState: ItemState<T> = {
    ids: [],
    entities: {},
    isLoading: false,
  };

  const slice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      if (options?.extraReducers) {
        options.extraReducers(builder);
      }

      // Fulfilled actions
      builder.addCase(get.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ids = action.payload.map((item: T) => item.id);
        state.entities = action.payload.reduce(
          (acc: Record<string, Draft<T>>, item: T) => {
            acc[item.id] = item as Draft<T>;

            return acc;
          },
          {} as Record<string, Draft<T>>,
        );
      });

      builder.addCase(create.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ids.push(action.payload.id);
        state.entities[action.payload.id] = action.payload as Draft<T>;
      });

      builder.addCase(getById.fulfilled, (state, action) => {
        state.isLoading = false;

        if (!state.ids.includes(action.payload.id)) {
          state.ids.push(action.payload.id);
        }

        state.entities[action.payload.id] = action.payload as Draft<T>;
      });

      builder.addCase(updateById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.entities[action.payload.id] = action.payload as Draft<T>;
      });

      builder.addCase(updateLocalById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.entities[action.payload.id] = action.payload as Draft<T>;
      });

      builder.addCase(deleteById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ids = state.ids.filter((id) => id !== action.payload);
        delete state.entities[action.payload];
      });

      // Pending actions
      builder.addMatcher(
        (action) => action.type.startsWith(`${name}/`) && action.type.endsWith("/pending"),
        (state) => {
          state.isLoading = true;
        },
      );

      // Rejected actions
      builder.addMatcher(
        (action) => action.type.startsWith(`${name}/`) && action.type.endsWith("/rejected"),
        (state) => {
          state.isLoading = false;
        },
      );
    },
  });

  return {
    actions: {
      ...slice.actions,
      get,
      create,
      getById,
      updateById,
      updateLocalById,
      deleteById,
    },
    reducer: slice.reducer,
  };
};
