import { RestList } from "../components/RestList";

type AdminAccountContributorsPageProps = {
  className?: string;
};

export const AdminAccountContributorsPage = ({ className }: AdminAccountContributorsPageProps) => {
  return (
    <RestList
      className={className}
      entityName="accountContributor"
      fields={[
        {
          name: "id",
          label: "ID",
          readOnly: true,
        },
        {
          name: "accountId",
          label: "Account Id",
        },
        {
          name: "voiceId",
          label: "Voice Id",
          visible: false,
        },
        {
          name: "name",
          label: "Name",
        },
        {
          name: "phone",
          label: "Phone",
          visible: false,
        },
        {
          name: "email",
          label: "Email",
          visible: false,
        },
        {
          name: "description",
          label: "Description",
          type: "textarea",
          visible: false,
        },
      ]}
    />
  );
};
