import { AddCircleOutline, CloseOutlined, DeleteOutline, EditOutlined, RefreshOutlined } from "@mui/icons-material";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";

import { Button } from "../components/Button";
import { capitalize, generate } from "../utils/strings";
import { InputField } from "../components/InputField";
import { EntityState, getActionsByName, useAppDispatch, useAppSelector } from "../redux";
import { Item, ItemState } from "../redux/reducers/restSlice";
import { CheckboxField } from "./CheckboxField";
import { CodeField } from "./CodeField";
import { DropdownField } from "./DropdownField";
import { FieldLabel } from "./FieldLabel";
import { CharacterListField } from "./CharacterListField";
import { MultiselectField } from "./MultiselectField";

type RestListFieldsProps<T> = {
  fields: Field<T>[];
  selectedEntityId: string;
  formData: T;
  onChangeFieldHandler: (key: string) => (value: string) => void;
};

const RestListFields = <T extends {}>({ fields, selectedEntityId, formData, onChangeFieldHandler }: RestListFieldsProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<string>("");

  const onClickOpenModalHandler = (fieldName: string) => () => {
    setModalContent(formData?.[fieldName as keyof T] as string);
    setIsModalOpen(true);
  };

  const onClickConfirmModalHandler = (fieldName: string) => () => {
    onChangeFieldHandler(fieldName)(modalContent);

    setIsModalOpen(false);
    setModalContent("");
  };

  const onClickCancelModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };

  const onChangeModalContent = (value: string) => {
    setModalContent(value);
  };

  return (
    <>
      {fields
        .filter((field) => !field.button)
        .map((field) =>
          field.component ? (
            React.createElement(field.component, {
              key: field.name,
              id: selectedEntityId,
              formData,
              value: formData?.[field.name as keyof T] as string,
              onChange: onChangeFieldHandler(field.name),
            })
          ) : field.type === "checkbox" ? (
            <CheckboxField
              key={field.name}
              className="border-[1px] border-gray-300 shadow-none"
              label={field.label}
              value={formData?.[field.name as keyof T] as boolean}
              onChange={onChangeFieldHandler(field.name) as unknown as (value: boolean) => void}
              readOnly={field.readOnly}
            />
          ) : field.type === "code" ? (
            <CodeField
              key={field.name}
              className="border-[1px] border-gray-300 shadow-none"
              label={field.label}
              value={formData?.[field.name as keyof T] as string}
              onChange={onChangeFieldHandler(field.name)}
              readOnly={field.readOnly}
            />
          ) : field.type === "json" ? (
            <CodeField
              key={field.name}
              mode="json"
              className="border-[1px] border-gray-300 shadow-none"
              label={field.label}
              value={formData?.[field.name as keyof T] as string}
              onChange={onChangeFieldHandler(field.name)}
              readOnly={field.readOnly}
            />
          ) : field.type === "dropdown" ? (
            <DropdownField
              key={field.name}
              className="!h-[42px]"
              label={field.label}
              options={field.options}
              onChange={onChangeFieldHandler(field.name)}
              readOnly={selectedEntityId !== "new" && field.readOnly}
              value={field.value ? field.value(formData?.[field.name as keyof T] as string) : (formData?.[field.name as keyof T] as string)}
            />
          ) : field.type === "searchable_dropdown" ? (
            <DropdownField
              key={field.name}
              searchable
              className="!h-[42px]"
              label={field.label}
              options={field.options}
              onChange={onChangeFieldHandler(field.name)}
              readOnly={selectedEntityId !== "new" && field.readOnly}
              value={field.value ? field.value(formData?.[field.name as keyof T] as string) : (formData?.[field.name as keyof T] as string)}
            />
          ) : field.type === "textarea_modal" ? (
            <FieldLabel
              key={field.name}
              label={field.label}
            >
              <Button
                className="mr-1 !h-[50px]"
                onClick={onClickOpenModalHandler(field.name)}
                type="outlined"
              >
                <EditOutlined />
              </Button>
              <Modal
                open={isModalOpen}
                onCancel={onClickCancelModal}
                onOk={onClickConfirmModalHandler(field.name)}
                className="!w-[80vh]"
              >
                <InputField
                  className="border-[1px] border-gray-300 shadow-none w-full !text-black !text-base min-h-[60vh] max-h-[60vh] !indent-0"
                  placeholder={field.label}
                  label={field.label}
                  value={modalContent}
                  onChange={onChangeModalContent}
                  type="textarea"
                />
              </Modal>
            </FieldLabel>
          ) : field.type === "character_list" ? (
            <CharacterListField
              label={field.label}
              value={formData?.[field.name as keyof T] as string[]}
              onChange={onChangeFieldHandler(field.name) as unknown as (value: string[]) => void}
            />
          ) : field.type === "multiselect" ? (
            <MultiselectField
              label={field.label}
              value={formData?.[field.name as keyof T] as string[]}
              onChange={onChangeFieldHandler(field.name) as unknown as (value: string[]) => void}
              options={field.options}
            />
          ) : (
            <InputField
              key={field.name}
              className="border-[1px] border-gray-300 shadow-none !indent-0 px-4"
              placeholder={field.label}
              label={field.label}
              value={formData?.[field.name as keyof T] as string}
              onChange={onChangeFieldHandler(field.name)}
              type={field.type}
              readOnly={field.readOnly}
            />
          ),
        )}
    </>
  );
};

export type RestListButtonProps<T> = {
  id: string;
  formData: T;
};

export type RestListComponentProps<T> = {
  id: string;
  formData: T;
  value: string;
  onChange: (value: string) => void;
};

export type Field<T> = {
  name: string;
  label: string;
  options?: { label: string; value: string }[];
  value?: (value: string) => string;
  type?: string;
  visible?: boolean;
  readOnly?: boolean;
  button?: React.JSXElementConstructor<RestListButtonProps<T>>;
  component?: React.JSXElementConstructor<RestListComponentProps<T>>;
};

export type RestListProps<T> = {
  className?: string;
  children?: React.ReactNode;
  entityName: keyof EntityState;
  fields: Field<T>[];
  onSelect?: () => void;
  sort?: (ids: string[], entities: Record<string, T>) => string[];
  transform?: (data: T) => T;
  editModal?: boolean;
  onSave?: () => void;
  onClose?: () => void;
  onChange?: (formData: T) => void;
};

export const RestList = <T extends Item>({
  children,
  className,
  entityName,
  fields,
  onSelect = () => {},
  sort = (ids) => ids,
  transform = (data: T) => data,
  editModal = false,
  onSave = () => {},
  onClose = () => {},
  onChange = () => {},
}: RestListProps<T>) => {
  const dispatch = useAppDispatch();
  const actions = getActionsByName(entityName);
  const entityIds = useAppSelector((state) => (state[entityName] as unknown as ItemState<T>).ids);
  const entityMap = useAppSelector((state) => (state[entityName] as unknown as ItemState<T>).entities);
  const isLoading = useAppSelector((state) => (state[entityName] as unknown as ItemState<T>).isLoading);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
  const [formData, setFormData] = useState<T>({} as T);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<string | null>(null);
  const visibleFields = fields.filter((field) => field.visible !== false);
  const visibleButtons = visibleFields.filter((field) => field.button).length || 0;
  const headerColumns = `${generate("1fr", visibleFields.filter((field) => !field.button).length, " ")} ${generate("64px", visibleButtons, " ")} 64px 64px`;
  const columns = `${generate("1fr", visibleFields.filter((field) => !field.button).length, " ")} ${generate("64px", visibleButtons, " ")} 64px 64px`;

  const onClickRowHandler = (id: string) => () => {
    setSearchParams({ id });
    onSelect();
  };

  const onClickCloseModal = () => {
    setIsOpenConfirmDelete(false);
    setSelectedDeleteId(null);
    setSearchParams({});
  };

  const onClickConfirmDelete = () => {
    if (selectedDeleteId) {
      dispatch(actions.deleteById(selectedDeleteId));
    }

    onClickCloseModal();
  };

  const onClickDeleteHandler = (id: string) => (event: React.MouseEvent) => {
    event.stopPropagation();

    setIsOpenConfirmDelete(true);
    setSelectedDeleteId(id);
  };

  const onClickCreate = async () => {
    // @ts-ignore
    await dispatch(actions.create(formData));

    setSearchParams({});
    onSave();
  };

  const onClickCreateModal = () => {
    onClickCreate();
    onClickClose();
    onClose();
  };

  const onClickUpdate = async () => {
    if (selectedEntityId) {
      const filteredFormData: Record<string, string> = {};

      Object.entries(formData).forEach(([key, value]) => {
        const field = fields.find((field) => field.name === key);

        if (field && !field.readOnly) {
          filteredFormData[key] = value;
        }
      });

      // @ts-ignore
      await dispatch(actions.updateById({ id: selectedEntityId, ...filteredFormData }));

      onSave();
    }
  };

  const onClickUpdateModal = () => {
    onClickUpdate();
    onClickClose();
    onClose();
  };

  const onChangeFieldHandler = (key: string) => (value: string) => {
    const newFormData = transform({
      ...formData,
      [key]: value,
    });

    setFormData(newFormData);
    onChange(newFormData);
  };

  const onClickAdd = () => {
    setSearchParams({ id: "new" });
  };

  const onClickRefresh = () => {
    // @ts-ignore
    dispatch(actions.get({ shallow: "true" }));
  };

  const onClickClose = () => {
    setSearchParams({});
    onClose();
  };

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id || id === "new") {
      setFormData({} as T);
    } else {
      setFormData(entityMap[id] as T);
    }

    setSelectedEntityId(id);
  }, [searchParams, entityMap]);

  useEffect(() => {
    const id = searchParams.get("id");

    if (id && id !== "new") {
      // @ts-ignore
      dispatch(actions.getById(id));
    }
  }, [searchParams]);

  useEffect(() => {
    // @ts-ignore
    dispatch(actions.get({ shallow: "true" }));
  }, []);

  return (
    <div className={clsx("flex flex-col items-center w-full h-full", className)}>
      <div className="flex flex-row w-full h-full">
        <div className="w-full flex flex-col">
          <div
            className="grid border-b-2 border-b-gray-300 pl-4"
            style={{ gridTemplateColumns: headerColumns }}
          >
            {visibleFields.map((field) =>
              field.button ? (
                <div key={field.name} />
              ) : (
                <span
                  key={field.name}
                  className="self-center px-2 py-4 mr-2 font-semibold"
                >
                  {field.label}
                </span>
              ),
            )}
            <Button
              className="h-full"
              type="basic"
              onClick={onClickAdd}
            >
              <AddCircleOutline className="text-blue-500 group-hover:text-blue-700" />
            </Button>
            <Button
              className="h-full"
              type="basic"
              onClick={onClickRefresh}
            >
              <RefreshOutlined className="text-gray-500 group-hover:text-gray-700" />
            </Button>
          </div>
          <div className="flex flex-col flex-grow overflow-y-auto w-full">
            {sort(entityIds, entityMap).map((id) => {
              const entity = entityMap[id];

              return (
                <div
                  key={id}
                  className={clsx("grid border-b-[1px] border-b-gray-300 pl-4 hover:bg-gray-100 active:bg-gray-200", selectedEntityId === id && "bg-gray-100")}
                  style={{ gridTemplateColumns: columns }}
                  onClick={onClickRowHandler(entity.id)}
                >
                  {visibleFields.map((field) =>
                    field.button ? (
                      React.createElement(field.button, {
                        key: field.name,
                        formData: entity,
                        id,
                      })
                    ) : field.type === "dropdown" ? (
                      <div
                        key={field.name}
                        className="self-center cursor-pointer truncate select-none mr-2 p-2 rounded-lg"
                      >
                        {String(field.options?.find((option) => option.value === entity?.[field.name as keyof typeof entity])?.label)}
                      </div>
                    ) : field.type === "checkbox" ? (
                      <div
                        key={field.name}
                        className="self-center cursor-pointer truncate select-none mr-2 p-2 rounded-lg"
                      >
                        {!!entity?.[field.name as keyof typeof entity] ? "✅" : "❌"}
                      </div>
                    ) : (
                      <div
                        key={field.name}
                        className="self-center cursor-pointer truncate select-none mr-2 p-2 rounded-lg"
                      >
                        {String(entity?.[field.name as keyof typeof entity])}
                      </div>
                    ),
                  )}
                  <Button
                    onClick={onClickDeleteHandler(entity.id)}
                    type="basic"
                  >
                    <DeleteOutline className="text-red-500 group-hover:text-red-700" />
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        {selectedEntityId && (
          <>
            {!editModal ? (
              <div className="border-l-[1px] border-l-gray-300 h-full">
                {children || (
                  <div className="flex flex-col w-[400px] min-w-[400px] h-full">
                    <div className="flex flex-row w-full items-center justify-between p-4 bg-gray-300">
                      {selectedEntityId === "new" ? (
                        <span className="text-xl font-semibold">Add {capitalize(entityName)}</span>
                      ) : (
                        <span className="text-xl font-semibold">Edit {capitalize(entityName)}</span>
                      )}
                      <Button
                        className="w-[50px] max-w-[50px]"
                        onClick={onClickClose}
                        type="basic"
                      >
                        <CloseOutlined className="text-gray-500 group-hover:text-gray-700" />
                      </Button>
                    </div>
                    <div className="p-4 flex flex-col flex-grow overflow-y-scroll">
                      <div className="flex-grow">
                        <RestListFields<T>
                          fields={fields}
                          selectedEntityId={selectedEntityId}
                          formData={formData}
                          onChangeFieldHandler={onChangeFieldHandler}
                        />
                      </div>
                    </div>
                    <div className="w-full p-4 border-t-[1px] border-t-gray-300">
                      {selectedEntityId === "new" ? (
                        <Button
                          className="w-full shadow-none"
                          onClick={onClickCreate}
                          isLoading={isLoading}
                        >
                          Create
                        </Button>
                      ) : (
                        <Button
                          className="w-full shadow-none"
                          onClick={onClickUpdate}
                          isLoading={isLoading}
                        >
                          Update
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Modal
                onCancel={onClickClose}
                onClose={onClickClose}
                onOk={selectedEntityId === "new" ? onClickCreateModal : onClickUpdateModal}
                confirmLoading={isLoading}
                width="80vw"
                bodyProps={{
                  style: {
                    height: "70vh",
                    overflow: "hidden",
                  },
                }}
                open
              >
                <div className="p-4 flex flex-col h-full">
                  <RestListFields<T>
                    fields={fields}
                    selectedEntityId={selectedEntityId}
                    formData={formData}
                    onChangeFieldHandler={onChangeFieldHandler}
                  />
                </div>
              </Modal>
            )}
          </>
        )}
      </div>
      <Modal
        open={isOpenConfirmDelete}
        onCancel={onClickCloseModal}
        onClose={onClickCloseModal}
        onOk={onClickConfirmDelete}
        centered
      >
        <span>Are you sure you want to delete this item?</span>
      </Modal>
    </div>
  );
};
