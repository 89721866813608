import { RestList, RestListComponentProps } from "../components/RestList";
import { World } from "../redux/reducers/worldSlice";
import { useEntity } from "../hooks/useEntity";
import { Character } from "../redux/reducers/characterSlice";
import { Avatar } from "../components/Avatar";
import { FieldLabel } from "../components/FieldLabel";
import { useNavigate } from "react-router-dom";

type AdminWorldsPageProps = {
  className?: string;
};

export const AdminWorldsPage = ({ className }: AdminWorldsPageProps) => {
  const navigate = useNavigate();
  const { entities: characterMap } = useEntity<Character>("character");

  const onClickCharacterHandler = (id: string) => () => {
    navigate(`/admin/characters?id=${id}`);
  };

  const fields = [
    {
      name: "imagePreview",
      label: "Image Preview",
      component: ({ formData }: RestListComponentProps<World>) => (
        <div
          className="flex flex-col items-center justify-center mb-4 w-full h-24 no-repeat bg-cover bg-center bg-gray-300 rounded-lg"
          style={{ backgroundImage: `url(${formData?.image})` }}
        />
      ),
      visible: false,
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "description",
      label: "Description",
    },
    {
      name: "image",
      label: "Image",
    },
    {
      name: "characters",
      label: "Characters",
      component: ({ formData }: RestListComponentProps<World>) => (
        <FieldLabel label="Characters">
          <div className="flex flex-wrap items-center justify-start w-full">
            {Object.values(characterMap)
              .filter((character) => character.worldId === formData?.id)
              .sort((a, b) => {
                const order = ["main", "guide", "companion", "animal"];
                return order.indexOf(a.role) - order.indexOf(b.role);
              })
              .map((character) => (
                <div
                  title={character.name}
                  className="w-16 h-16 mr-2 mb-2"
                >
                  <Avatar
                    key={character.id}
                    name={character.name}
                    role={character.role}
                    image={character.image}
                    characterId={character.id}
                    className="!w-16 !h-16"
                    onClick={onClickCharacterHandler(character.id)}
                  />
                </div>
              ))}
          </div>
        </FieldLabel>
      ),
      visible: false,
    },
  ];

  return (
    <RestList<World>
      className={className}
      entityName="world"
      fields={fields}
      sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.name.localeCompare(entities[b]?.name))}
    />
  );
};
