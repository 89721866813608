import { RestList } from "../components/RestList";

type AdminUsersPageProps = {
  className?: string;
};

export const AdminUsersPage = ({ className }: AdminUsersPageProps) => {
  return (
    <RestList
      className={className}
      entityName="user"
      fields={[
        {
          name: "email",
          label: "Email",
        },
        {
          name: "isAdmin",
          label: "Admin",
        },
        {
          name: "accountId",
          label: "Account Id",
        },
      ]}
    />
  );
};
