import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";

import { AudioPlayer, AudioPlayerRef } from "./AudioPlayer";
import { Book } from "./Book";
import { BookAvatarBar } from "./BookAvatarBar";
import { Character } from "../redux/reducers/characterSlice";
import { CHARACTER_COLORS } from "../constants/colors";
import { Image } from "./Image";
import { QuestionBar } from "./QuestionBar";
import { Slide } from "./Slide";
import { SlideGroup } from "./SlideGroup";
import { Story, storyActions } from "../redux/reducers/storySlice";
import { storyChunkActions } from "../redux/reducers/storyChunkSlice";
import { StoryListCard } from "./StoryListCard";
import { StorySet } from "../redux/reducers/storySetSlice";
import { StorySetCreateCard } from "./StorySetCreateCard";
import { StorySetListCard } from "./StorySetListCard";
import { StoryTitleCard } from "./StoryTitleCard";
import { StoryTitleSelectCard } from "./StoryTitleSelectCard";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "./View";
import { World } from "../redux/reducers/worldSlice";
import { StoryPlotCard } from "./StoryPlotCard";

type WorldCardProps = {
  characters?: Character[];
  className?: string;
  storySets?: StorySet[];
  stories?: Story[];
  style?: React.CSSProperties;
  world: World;
  isSelected?: boolean;
  slideIndexLeft?: number;
  slideIndexRight?: number;
  onClickCreateStorySet: (worldId: string, details: string, characters: Record<string, string>) => void;
  createStorySetLoading?: boolean;
  newStorySetName?: string;
  newStories?: Story[];
};

export const WorldCard = ({
  characters = [],
  className,
  isSelected = false,
  storySets = [],
  stories = [],
  newStories = [],
  style,
  world,
  // slideIndexLeft = 0,
  slideIndexRight = 0,
  onClickCreateStorySet = () => {},
  newStorySetName,
  createStorySetLoading = false,
}: WorldCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideIndexLeft, setSlideIndexLeft] = useState<number>(0);
  const [selectedStorySetId, setSelectedStorySetId] = useState<string>("");
  const [selectedStoryId, setSelectedStoryId] = useState<string>("");
  const storyEntities = useAppSelector((state) => state.story.entities);
  const selectedStory = useAppSelector((state) => state.story.entities[selectedStoryId]);

  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [wordToDefine, setWordToDefine] = useState<string>("");
  const [isQuestionBarOpen, setIsQuestionBarOpen] = useState<boolean>(false);
  const defineWordApiRequest = useApiRequest<{ audioUrl: string; definition: string }>();
  const storyChunkEntities = useAppSelector((state) => state.storyChunk.entities);
  const audioRef = useRef<AudioPlayerRef>(null);
  const [selectedNewStory, setSelectedNewStory] = useState<Story>();
  const [selectedCharacters, setSelectedCharacters] = useState<Record<string, string>>({
    narrator: "",
    character_1: "",
    character_2: "",
    character_3: "",
  });

  const selectedStories = useMemo(() => {
    const storySet = storySets.find((storySet) => storySet.id === selectedStorySetId);
    if (!storySet) {
      return [];
    }

    return storySet.storyIds.map((storyId) => stories.find((story) => story.id === storyId)).filter((story) => story) as Story[];
  }, [storySets, stories, selectedStorySetId]);

  const storyCharacters = useMemo<Character[]>(() => {
    if (!selectedStory) {
      return [];
    }

    return [selectedStory.narrator].concat(selectedStory.speakingCharacters);
  }, [selectedStoryId]);

  const storyChunks = useMemo(() => {
    const storyId = searchParams.get("s");

    return Object.values(storyChunkEntities)
      .filter((chunk) => chunk.storyId === storyId)
      .sort((a, b) => a.index - b.index)
      .map((chunk) => ({
        ...chunk,
        color: CHARACTER_COLORS[storyCharacters.findIndex((character) => character?.id === chunk.characterId) || 0],
      }));
  }, [storyCharacters, storyChunkEntities, searchParams]);

  const currentCharacter = useMemo(() => {
    const characterId = selectedStory?.analysis?.words[currentWordIndex]?.characterId;

    return storyCharacters.find((character) => characterId === character?.id);
  }, [storyCharacters, storyChunks, currentWordIndex]);

  const currentCharacterColors = useMemo(() => {
    return storyCharacters.reduce(
      (acc, character, index) => {
        acc[character?.id] = CHARACTER_COLORS[index];

        return acc;
      },
      {} as Record<string, string>,
    );
  }, [storyCharacters]);

  const selectedStoryCharacters = useMemo(() => {
    if (!selectedStory) {
      return [];
    }

    return [selectedStory.narrator]
      .concat(selectedStory.speakingCharacters || [])
      .concat(selectedStory.nonSpeakingCharacters || [])
      .reduce((acc, character) => {
        if (acc.find((c) => c.id === character.id)) {
          return acc;
        }

        return acc.concat(character);
      }, [] as Character[]);
  }, [selectedStory]);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickCharacter = () => {
    audioRef.current?.pause();
  };

  const onClickPlayWord = (index: number) => {
    const wordStart = selectedStory?.analysis?.words[index]?.start || 0;

    audioRef.current?.setCurrentTime((wordStart + 10) / 1000);
    audioRef.current?.play();
  };

  const onClickDefineWord = (index: number) => {
    const wordText = selectedStory?.analysis?.words[index]?.text || "";

    audioRef.current?.pause();

    setWordToDefine(wordText);
    setIsQuestionBarOpen(true);

    defineWordApiRequest.fetch(`/v1/stories/${selectedStory?.id}/define-word`, {
      method: "POST",
      body: {
        word: wordText,
      },
    });
  };

  const onChangeWordIndex = (index: number) => {
    setCurrentWordIndex(index);
  };

  const onClickCloseQuestionBar = () => {
    setIsQuestionBarOpen(false);

    setTimeout(() => {
      audioRef.current?.play();
    }, 700);
  };

  const onChangeSelectedCharacters = (newSelectedCharacters: Record<string, string>) => {
    setSelectedCharacters(newSelectedCharacters);
  };

  const onClickCreateStorySetInternal = (details: string) => {
    onClickCreateStorySet(world.id, details, selectedCharacters);
    setSelectedStorySetId("new");
    setSlideIndexLeft(1);
  };

  const onClickNewStory = (storyId: string) => {
    setSelectedNewStory(newStories.find((story) => story.id === storyId));
  };

  const onClickStoryListBack = () => {
    setSlideIndexLeft(0);
  };

  const onClickStorySet = (storySetId: string) => {
    setSelectedStorySetId(storySetId);
    setSlideIndexLeft(1);
  };

  const onClickStory = (storyId: string) => {
    navigate(`/library?s=${storyId}`);
    setSelectedStoryId(storyId);
    setCurrentSlide(1);
  };

  const onClickRead = () => {
    navigate(`/library?s=${selectedStoryId}&p=1`);
    setCurrentSlide(2);
  };

  useEffect(() => {
    const storyId = searchParams.get("s") || "";
    const isPlaying = searchParams.get("p") === "1";
    const story = storyEntities[storyId];

    if (storyId && isPlaying) {
      if (!story) {
        dispatch(storyActions.getById(storyId));
      }

      dispatch(storyChunkActions.getByStoryId({ storyId, languageCode: "en-us" }));
      setCurrentSlide(2);
    } else if (storyId && !isPlaying) {
      if (!story) {
        dispatch(storyActions.getById(storyId));
      }

      setCurrentSlide(1);
    } else {
      setCurrentSlide(0);
    }
  }, [searchParams, storyEntities]);

  useEffect(() => {
    if (currentSlide === 2) {
      setTimeout(() => {
        audioRef.current?.play();
      }, 1500);
    } else {
      audioRef.current?.pause();
    }
  }, [currentSlide]);

  return (
    <View
      className={clsx("relative w-full bg-black", className)}
      style={style}
    >
      <SlideGroup
        currentSlide={currentSlide}
        className="!h-[100vh] z-10"
      >
        <Slide className="w-full flex !flex-row">
          <SlideGroup
            className="relative flex items-center justify-center w-full h-full sm:!w-1/2 sm:mr-8 z-10"
            direction="vertical"
            currentSlide={slideIndexLeft}
          >
            <Slide>
              <StorySetListCard
                className="w-full h-[60%] mt-12"
                title={world.name}
                storySets={storySets}
                onClickStorySet={onClickStorySet}
              />
            </Slide>
            <Slide>
              {selectedStorySetId === "new" ? (
                <StorySetCreateCard
                  className="w-full h-[60%] mt-12"
                  isLoading={createStorySetLoading}
                  onClickBack={onClickStoryListBack}
                  onClickCreate={onClickCreateStorySetInternal}
                />
              ) : (
                <StoryListCard
                  className="w-full h-[60%] mt-12"
                  title={world.name}
                  stories={selectedStories}
                  onClickBack={onClickStoryListBack}
                  onClickStory={onClickStory}
                />
              )}
            </Slide>
            <Slide>
              <StoryListCard
                className="w-full h-[60%] mt-12"
                title={newStorySetName}
                stories={stories}
                onClickBack={onClickBack}
                onClickStory={onClickNewStory}
                selectedStoryId={selectedNewStory?.id}
              />
            </Slide>
          </SlideGroup>
          <SlideGroup
            className="relative flex items-center justify-center w-full h-full sm:!w-[40%] z-10"
            direction="vertical"
            currentSlide={slideIndexRight}
          >
            <Slide>
              <StoryTitleCard
                characters={characters}
                className="w-full h-[60%] mt-12"
              />
            </Slide>
            <Slide>
              <StoryTitleSelectCard
                selectedCharacters={selectedCharacters}
                characters={characters}
                onChange={onChangeSelectedCharacters}
                className="w-full h-[60%] mt-12"
              />
            </Slide>
            <Slide>
              <StoryPlotCard
                className="w-full h-[60%] mt-12"
                title={selectedNewStory?.title}
                content={selectedNewStory?.content}
              />
            </Slide>
          </SlideGroup>
        </Slide>
        <Slide>
          {selectedStory && isSelected ? (
            <StoryTitleCard
              className="min-w-[40%] mt-12"
              characters={selectedStoryCharacters}
              title={selectedStory.title}
              onClick={onClickRead}
              showReadButton
            />
          ) : (
            <View />
          )}
        </Slide>
        <Slide>
          {selectedStory && isSelected ? (
            <View className={clsx("w-full h-full transition-all duration-700 pt-20 pb-40")}>
              <Book
                characterColors={currentCharacterColors}
                currentWordIndex={currentWordIndex}
                onClickPlayWord={onClickPlayWord}
                onClickDefineWord={onClickDefineWord}
                words={selectedStory?.analysis?.words}
                className="!z-0"
              />
              <BookAvatarBar
                className={clsx(
                  "absolute bottom-0 left-1/2 -translate-x-1/2 z-50 transition-all duration-700 w-[100vw] md:w-auto md:max-w-[80vw]",
                  currentSlide !== 2 ? "translate-y-[500px] bottom-0" : "translate-y-0",
                )}
                characters={selectedStoryCharacters}
                currentCharacterId={currentCharacter?.id}
                characterColors={currentCharacterColors}
                onClickCharacter={onClickCharacter}
                isOpen
              />
              <AudioPlayer
                ref={audioRef}
                analysis={selectedStory?.analysis}
                audioUrl={selectedStory?.audioUrl}
                className={clsx(
                  "absolute right-1/2 translate-x-1/2 md:bottom-4 md:right-4 md:translate-x-0 bg-white bg-opacity-80 backdrop-blur-md rounded-full transition-all duration-700 z-50",
                  currentSlide !== 2 ? "translate-y-[500px] bottom-0" : "-translate-y-4",
                )}
                currentWordIndex={currentWordIndex}
                onChangeWordIndex={onChangeWordIndex}
                isCollapsable
              />
              <QuestionBar
                className="!z-50"
                narrator={selectedStory?.narrator}
                audioUrl={defineWordApiRequest.data?.audioUrl}
                word={wordToDefine}
                definition={defineWordApiRequest.data?.definition}
                isOpen={isQuestionBarOpen}
                isLoading={defineWordApiRequest.isLoading}
                onClickClose={onClickCloseQuestionBar}
              />
            </View>
          ) : (
            <View />
          )}
        </Slide>
      </SlideGroup>
      <Image
        src={world.image}
        alt={world.name}
        className="absolute top-0 left-0 object-cover h-full w-full z-0"
      />
    </View>
  );
};
