import { useEffect, useState } from "react";

import { AudioPlayer } from "../components/AudioPlayer";
import { AudioStory, AudioStoryVersion } from "../redux/reducers/audioStorySlice";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { RestList, RestListComponentProps } from "../components/RestList";
import { Text } from "../components/Text";
import { useApiRequest } from "../hooks/useApiRequest";
import { View } from "../components/View";
import { InputField } from "../components/InputField";
import { Delete, DeleteOutlined } from "@mui/icons-material";

type AudioStoryVersionsModalProps = {
  audioStoryId: string;
  isOpen: boolean;
  onClose: () => void;
};

const AudioStoryVersionsModal = ({ audioStoryId, isOpen, onClose }: AudioStoryVersionsModalProps) => {
  const [userId, setUserId] = useState<string>("");
  const [accountContributorId, setAccountContributorId] = useState<string>("");
  const apiRequestCreateVersion = useApiRequest<never>();
  const apiRequestDeleteVersion = useApiRequest<never>();
  const apiRequestGetVersions = useApiRequest<{ data: AudioStoryVersion[] }>();

  const onClickCreateVersion = async () => {
    await apiRequestCreateVersion.fetch(`/v1/audio/${audioStoryId}/versions`, {
      method: "POST",
      body: {
        userId,
        accountContributorIds: [accountContributorId],
      },
    });

    await apiRequestGetVersions.fetch(`/v1/audio/${audioStoryId}/versions`);

    setUserId("");
    setAccountContributorId("");
  };

  const onClickDeleteVersionHandler = (versionId: string) => async () => {
    await apiRequestDeleteVersion.fetch(`/v1/audio/${audioStoryId}/versions/${versionId}`, {
      method: "DELETE",
    });

    await apiRequestGetVersions.fetch(`/v1/audio/${audioStoryId}/versions`);
  };

  useEffect(() => {
    if (audioStoryId) {
      apiRequestGetVersions.fetch(`/v1/audio/${audioStoryId}/versions`);

      setUserId("");
      setAccountContributorId("");
    }
  }, [audioStoryId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Audio Story Versions"
      className="min-w-[80vw]"
    >
      <View className="flex flex-col w-full">
        <View
          className="grid w-full gap-4 mb-4"
          style={{ gridTemplateColumns: "1fr 1fr 1fr" }}
        >
          <InputField
            name="userId"
            placeholder="User Id"
            value={userId}
            onChange={(value) => setUserId(value)}
          />
          <InputField
            name="accountContributorId"
            placeholder="Contributor Id"
            value={accountContributorId}
            onChange={(value) => setAccountContributorId(value)}
          />
          <Button
            type="outlined"
            onClick={onClickCreateVersion}
            isLoading={apiRequestCreateVersion.isLoading}
          >
            Create Version
          </Button>
        </View>
        <View
          className="grid w-full pb-2 font-semibold border-b border-gray-300"
          style={{ gridTemplateColumns: "1fr 1fr 1fr 64px" }}
        >
          <View>User Id</View>
          <View>Contributor Id</View>
          <View>Audio</View>
        </View>
        {apiRequestGetVersions.isLoading ? (
          <View className="flex flex-row items-center justify-center w-full h-64">Loading...</View>
        ) : (
          <View className="w-full overflow-y-auto">
            {apiRequestGetVersions.data?.data?.map((version) => (
              <View
                className="grid items-center border-b border-gray-300"
                style={{ gridTemplateColumns: "1fr 1fr 1fr 64px" }}
              >
                <Text>{version.userId}</Text>
                <Text>{version.accountContributorIds}</Text>
                <AudioPlayer
                  audioUrl={version.audioUrl}
                  size="small"
                />
                <Button
                  type="basic"
                  onClick={onClickDeleteVersionHandler(version.id)}
                  isLoading={apiRequestDeleteVersion.isLoading}
                >
                  <DeleteOutlined className="text-red-500" />
                </Button>
              </View>
            ))}
            {apiRequestGetVersions.data?.data?.length === 0 && (
              <View className="flex flex-row items-center justify-center w-full h-64">No versions found.</View>
            )}
          </View>
        )}
      </View>
    </Modal>
  );
};

type AdminAudioStoriesPageProps = {
  className?: string;
};

export const AdminAudioStoriesPage = ({ className }: AdminAudioStoriesPageProps) => {
  const [selectedAudioStoryId, setSelectedAudioStoryId] = useState<string>("");

  const fields = [
    {
      name: "title",
      label: "Title",
    },
    {
      name: "content",
      label: "Content",
      type: "textarea",
    },
    {
      name: "audioPlayer",
      label: "Audio Player",
      component: ({ formData }: RestListComponentProps<AudioStory>) =>
        formData?.baseAudioUrl ? (
          <AudioPlayer
            className="flex-grow mb-4 px-0"
            audioUrl={formData?.baseAudioUrl}
          />
        ) : null,
      visible: false,
    },
    {
      name: "actions",
      label: "Actions",
      visible: false,
      component: ({ id }: RestListComponentProps<AudioStory>) =>
        id !== "new" ? (
          <View className="flex flex-col items-center justify-center w-full">
            <Button
              className="mb-2"
              type="outlined"
              onClick={() => setSelectedAudioStoryId(id)}
            >
              View Versions
            </Button>
          </View>
        ) : null,
    },
  ];

  return (
    <>
      <RestList<AudioStory>
        className={className}
        entityName="audioStory"
        fields={fields}
        sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.title.localeCompare(entities[b]?.title))}
      />
      <AudioStoryVersionsModal
        audioStoryId={selectedAudioStoryId}
        isOpen={!!selectedAudioStoryId}
        onClose={() => setSelectedAudioStoryId("")}
      />
    </>
  );
};
