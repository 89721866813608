import { createRestSlice } from "./restSlice";

export type AccountReader = {
  id: string;
  name: string;
  description: string;
  image: string;
  accountId: string;
  createdAt: string;
  updatedAt: string;
};

const accountReaderSlice = createRestSlice<AccountReader>("accountReader", "/v1/accountReaders");

export const accountReaderActions = accountReaderSlice.actions;
export const accountReaderReducer = accountReaderSlice.reducer;
