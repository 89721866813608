import { useState } from "react";

import { AudioPlayer } from "../components/AudioPlayer";
import { AudioStory } from "../redux/reducers/audioStorySlice";
import { Button } from "../components/Button";
import { RestList, RestListComponentProps } from "../components/RestList";
import { View } from "../components/View";
import { useEntity } from "../hooks/useEntity";
import { AudioStoryPlaylist } from "../redux/reducers/audioStoryPlaylistSlice";

type AdminAudioStoriesPageProps = {
  className?: string;
};

export const AdminAudioStoryPlaylistsPage = ({ className }: AdminAudioStoriesPageProps) => {
  const { entities: audioStoryEntities } = useEntity<AudioStory>("audioStory");

  const fields = [
    {
      name: "title",
      label: "Title",
    },
    {
      name: "tags",
      label: "Tags (comma separated)",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
    },
    {
      name: "audioStoryIds",
      label: "Audio Stories",
      type: "multiselect",
      options: Object.values(audioStoryEntities).map((audioStory) => ({
        label: audioStory.title,
        value: audioStory.id,
      })),
      visible: false,
    },
  ];

  return (
    <RestList<AudioStoryPlaylist>
      className={className}
      entityName="audioStoryPlaylist"
      fields={fields}
      sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.title.localeCompare(entities[b]?.title))}
    />
  );
};
