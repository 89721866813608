import { RestList } from "../components/RestList";

type AdminAccountsPageProps = {
  className?: string;
};

export const AdminAccountsPage = ({ className }: AdminAccountsPageProps) => {
  return (
    <RestList
      className={className}
      entityName="account"
      fields={[
        {
          name: "id",
          label: "ID",
          readOnly: true,
        },
        {
          name: "name",
          label: "Name",
        },
      ]}
    />
  );
};
