import { AddAPhotoOutlined, AddRounded, EditRounded } from "@mui/icons-material";
import { clsx } from "clsx";

import { View } from "./View";
import { Button } from "./Button";
import { Image } from "./Image";

type AvatarUploadProps = {
  className?: string;
  onChange?: (value: string) => void;
  value?: string;
};

export const AvatarUpload = ({ className, value, onChange = () => {} }: AvatarUploadProps) => {
  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target?.result;

        if (typeof result === "string") {
          onChange(result);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <View
      className={clsx(
        "relative flex items-center justify-center !w-[200px] !h-[200px] border-4 !rounded-full",
        value ? "border-white" : "border-secondary",
        className,
      )}
    >
      {value ? (
        <>
          <Image
            className="w-full h-full rounded-full"
            src={value}
          />
          <Button
            type="basic"
            className="!absolute !w-12 !h-12 bottom-0 right-0 bg-secondary !rounded-full active:scale-90"
          >
            <EditRounded
              className="!w-8 !h-8"
              htmlColor="#ffffff"
            />
          </Button>
        </>
      ) : (
        <View className="flex items-center justify-center w-full h-full bg-gray-200 rounded-full border-[12px] border-white">
          <AddRounded
            className="!w-[100px] !h-[100px]"
            htmlColor="#21ADAA"
          />
        </View>
      )}
      <input
        type="file"
        className="absolute top-0 left-0 right-0 bottom-0 opacity-0"
        onChange={onChangeFile}
      />
    </View>
  );
};
