import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { AdminCharactersPage } from "./pages/AdminCharactersPage";
import { AdminLayout } from "./layouts/AdminLayout";
import { AdminStoriesPage } from "./pages/AdminStoriesPage";
import { AdminStoryTemplatesPage } from "./pages/AdminStoryTemplatesPage";
import { AdminUsersPage } from "./pages/AdminUsersPage";
import { AdminWorldsPage } from "./pages/AdminWorldsPage";
import { AppLayout } from "./layouts/AppLayout";
import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout";
import { HomePage } from "./pages/HomePage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { OnboardingContributorPage } from "./pages/OnboardingContributorPage";
import { OnboardingCompletePage } from "./pages/OnboardingCompletePage";
import { OnboardingOwnerPage } from "./pages/OnboardingOwnerPage";
import { OnboardingLayout } from "./layouts/OnboardingLayout";
import { ProfilePage } from "./pages/ProfilePage";
import { SignInPage } from "./pages/SignInPage";
import { store } from "./redux";
import { StoryPage } from "./pages/StoryPage";
import { RedirectPage } from "./pages/RedirectPage";
import { AdminThemesPage } from "./pages/AdminThemesPage";
import { AdminStoryBuilderPage } from "./pages/AdminStoryBuilderPage";
import { AdminVoicesPage } from "./pages/AdminVoicesPage";
import { OnboardingAccountTypePage } from "./pages/OnboardingAccountTypePage";
import { AdminStorySetsPage } from "./pages/AdminStorySetsPage";
import { AdminStoryTemplateSetsPage } from "./pages/AdminStoryTemplateSetsPage";
import { LibraryPage } from "./pages/LibraryPage";
import { SearchPage } from "./pages/SearchPage";
import { AdminAccountReadersPage } from "./pages/AdminAccountReadersPage";
import { AdminAccountsPage } from "./pages/AdminAccountsPage";
import { AdminAudioStoriesPage } from "./pages/AdminAudioStoriesPage";
import { AdminAccountContributorsPage } from "./pages/AdminAccountContributorsPage";
import { AdminAudioStoryPlaylistsPage } from "./pages/AdminAudioStoryPlaylistsPage";

export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<AppLayout />}
          >
            <Route
              path="/"
              element={<AuthenticatedLayout />}
            >
              <Route
                path="/"
                element={<HomePage />}
              />
              <Route
                path="/library"
                element={<LibraryPage />}
              />
              <Route
                path="/search"
                element={<SearchPage />}
              />
              <Route
                path="/story"
                element={<StoryPage />}
              />
              <Route
                path="/profile"
                element={<ProfilePage />}
              />
            </Route>
            <Route
              path="/onboarding"
              element={<OnboardingLayout />}
            >
              <Route
                path="/onboarding"
                element={<OnboardingAccountTypePage />}
              />
              <Route
                path="/onboarding/contributor"
                element={<OnboardingContributorPage />}
              />
              <Route
                path="/onboarding/owner"
                element={<OnboardingOwnerPage />}
              />
              <Route
                path="/onboarding/complete"
                element={<OnboardingCompletePage />}
              />
            </Route>
            <Route
              path="/admin"
              element={<AdminLayout />}
            >
              <Route
                path="/admin"
                element={<RedirectPage path="/admin/stories" />}
              />
              <Route
                path="/admin/stories"
                element={<AdminStoriesPage />}
              />
              <Route
                path="/admin/storySets"
                element={<AdminStorySetsPage />}
              />
              <Route
                path="/admin/storyTemplates"
                element={<AdminStoryTemplatesPage />}
              />
              <Route
                path="/admin/storyTemplateSets"
                element={<AdminStoryTemplateSetsPage />}
              />
              <Route
                path="/admin/themes"
                element={<AdminThemesPage />}
              />
              <Route
                path="/admin/characters"
                element={<AdminCharactersPage />}
              />
              <Route
                path="/admin/users"
                element={<AdminUsersPage />}
              />
              <Route
                path="/admin/accounts"
                element={<AdminAccountsPage />}
              />
              <Route
                path="/admin/accountReaders"
                element={<AdminAccountReadersPage />}
              />
              <Route
                path="/admin/accountContributors"
                element={<AdminAccountContributorsPage />}
              />
              <Route
                path="/admin/voices"
                element={<AdminVoicesPage />}
              />
              <Route
                path="/admin/worlds"
                element={<AdminWorldsPage />}
              />
              <Route
                path="/admin/storyBuilder"
                element={<AdminStoryBuilderPage />}
              />
              <Route
                path="/admin/audioStories"
                element={<AdminAudioStoriesPage />}
              />
              <Route
                path="/admin/audioStoryPlaylists"
                element={<AdminAudioStoryPlaylistsPage />}
              />
            </Route>
            <Route
              path="/signin"
              element={<SignInPage />}
            />
            <Route
              path="*"
              element={<NotFoundPage />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
