import clsx from "clsx";

import { Button } from "./base/Button";
import { Card } from "./base/Card";
import { Icon } from "./base/Icon";
import { ProgressBar } from "./ProgressBar";
import { Style } from "../utils/types";
import { Text } from "./base/Text";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "./base/View";

type OnboardingCardProps = {
  backText?: string;
  children?: React.ReactNode;
  className?: string;
  continueDisabled?: boolean;
  continueLoading?: boolean;
  continueText?: string;
  currentStep?: number;
  totalSteps?: number;
  onClickBack?: () => void;
  onClickContinue?: () => void;
  style?: Style;
  subtitle?: string;
  title?: string | React.ReactNode;
};

export const OnboardingCard = ({
  backText,
  children,
  className,
  continueDisabled = false,
  continueLoading = false,
  continueText = "Continue",
  onClickBack,
  onClickContinue,
  currentStep,
  totalSteps,
  style,
  subtitle,
  title,
}: OnboardingCardProps) => {
  const navigation = useNavigation();

  const onClickBackIcon = () => {
    navigation.goBack();
  };

  return (
    <Card
      className={clsx("p-8 max-w-[90%] md:max-w-sm w-full", className)}
      style={style}
    >
      {currentStep && totalSteps && (
        <View className="flex flex-row items-center justify-between mb-4">
          <Button
            className="mr-2 -ml-2"
            type="basic"
            onClick={onClickBackIcon}
          >
            <Icon
              name="arrow-back"
              size={24}
              className="text-title"
            />
          </Button>
          <ProgressBar
            className="flex-grow"
            progress={(currentStep / totalSteps) * 100}
          />
          <Text className="text-title ml-2">
            {currentStep} / {totalSteps}
          </Text>
        </View>
      )}
      {title && (
        <View className="mb-8">
          <Text className="text-3xl text-left text-title">{title}</Text>
          {subtitle && <Text className="text-lg text-left text-subtitle mt-2">{subtitle}</Text>}
        </View>
      )}
      {children}
      {onClickContinue && (
        <Button
          color="primary"
          onClick={onClickContinue}
          isDisabled={continueDisabled}
          isLoading={continueLoading}
        >
          {continueText}
        </Button>
      )}
      {onClickBack && (
        <Button
          type="link"
          color="white"
          onClick={onClickBack}
          className="pb-0 mt-4"
        >
          {backText}
        </Button>
      )}
    </Card>
  );
};
