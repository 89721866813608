import { createRestSlice } from "./restSlice";

export type AccountContributor = {
  id: string;
  name?: string;
  image?: string;
  description?: string;
  phone?: string;
  email?: string;
};

export type AccountReader = {
  id: string;
  name?: string;
  image?: string;
  description?: string;
};

export type Account = {
  id: string;
  name?: string;
  readers?: AccountReader[];
  contributors?: AccountContributor[];
};

export type User = {
  id: string;
  email: string;
  phone?: string;
  name?: string;
  image?: string;
  relationship?: string;
  avatar?: string;
  isAdmin: boolean;
  accountId?: string;
  account?: Account;
};

const userSlice = createRestSlice<User>("user", "/v1/users");

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
