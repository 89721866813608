import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux";
import { sessionActions } from "../redux/reducers/sessionSlice";

export const AppLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const isLoading = useAppSelector((state) => state.session.isLoadingSession);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(sessionActions.getSession());
  }, []);

  useEffect(() => {
    if (user && pathname === "/signin") {
      navigate(searchParams.get("redirect") || "/");
    }
  }, [user, pathname]);

  return isLoading ? (
    <div className="flex items-center justify-center w-full h-full">
      <div className="w-16 h-16 border-t-2 border-b-2 border-white rounded-full animate-spin" />
    </div>
  ) : (
    <Outlet />
  );
};
