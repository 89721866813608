import clsx from "clsx";
import { CheckRounded } from "@mui/icons-material";

import { View } from "./View";
import { Button } from "./Button";
import { Image } from "./Image";

type SelectableImageCardProps = {
  className?: string;
  onClick?: () => void;
  progress?: number;
  image?: string;
  isSelected?: boolean;
};

export const SelectableImageCard = ({ className, onClick, image, isSelected = false }: SelectableImageCardProps) => {
  return (
    <Button
      className={clsx(
        "relative flex justify-between w-full h-28 min-h-28 rounded-md",
        isSelected ? "border-secondary border-[3px] p-1" : "border-gray-200 border-2 p-[5px]",
        className,
      )}
      onClick={onClick}
      type="basic"
    >
      <Image
        className="w-full h-full"
        src={image}
      />
      <View
        className={clsx(
          "absolute flex items-center justify-center w-8 h-8 min-w-8 min-h-8 rounded-full",
          isSelected
            ? "bg-secondary border-[1px] border-secondary top-[15px] right-[15px]"
            : "bg-white bg-opacity-50 border-[1px] border-gray-200 top-4 right-4",
        )}
      >
        {isSelected && <CheckRounded htmlColor="#ffffff" />}
      </View>
    </Button>
  );
};
