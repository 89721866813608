import { createRestSlice } from "./restSlice";

export type Account = {
  id: string;
  accountId: string;
  readerId: string;
  createdAt: string;
  updatedAt: string;
};

const accountSlice = createRestSlice<Account>("account", "/v1/accounts");

export const accountActions = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
