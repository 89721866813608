import { DoubleArrowRounded, Pause, PlayArrow, VolumeOff, VolumeUp } from "@mui/icons-material";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "./Button";
import { StoryAnalysis } from "../redux/reducers/storySlice";
import { Text } from "./Text";
import { View } from "./View";

export type AudioPlayerRef = {
  play: () => void;
  pause: () => void;
  setCurrentTime: (time: number) => void;
};

type AudioPlayerProps = {
  analysis?: StoryAnalysis;
  audioUrl?: string;
  className?: string;
  currentWordIndex?: number;
  isCollapsable?: boolean;
  onChangeWordIndex?: (index: number) => void;
  onClickPause?: () => void;
  onClickPlay?: () => void;
  onFinish?: () => void;
  size?: "small" | "large";
};

const secondsToTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const AudioPlayer = React.forwardRef<AudioPlayerRef, AudioPlayerProps>(
  (
    {
      audioUrl,
      analysis,
      className,
      isCollapsable = false,
      onClickPlay = () => {},
      onClickPause = () => {},
      onChangeWordIndex = () => {},
      onFinish = () => {},
      size = "large",
    },
    ref,
  ) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const animationFrame = useRef<number | null>(null);
    const progressBarRef = useRef<HTMLDivElement | null>(null);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [showProgressBar, setShowProgressBar] = useState<boolean>(isCollapsable ? false : true);
    const [duration, setDuration] = useState<number>(0);

    const onClickPlayInternal = () => {
      if (isPlaying) {
        audioRef.current?.pause();

        onClickPause();
      } else {
        audioRef.current?.play();

        onClickPlay();
      }

      setIsPlaying(!isPlaying);
    };

    const onClickMuteInternal = () => {
      audioRef.current!.muted = !audioRef.current!.muted;

      setIsMuted(audioRef.current!.muted);
    };

    const onMouseDownProgress = () => {
      const onMouseMove = (event: MouseEvent) => {
        const progress = (event.clientX - progressBarRef.current!.getBoundingClientRect().left) / progressBarRef.current!.clientWidth;

        audioRef.current!.currentTime = progress * duration;
      };

      const onMouseUp = () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      };

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    };

    const onClickProgress = (event: React.MouseEvent) => {
      const progress = (event.clientX - event.currentTarget.getBoundingClientRect().left) / event.currentTarget.clientWidth;

      if (audioRef.current) {
        audioRef.current.currentTime = duration * progress;
      }
    };

    const onClickShowProgressBar = () => {
      setShowProgressBar(!showProgressBar);
    };

    useEffect(() => {
      const updateProgress = () => {
        animationFrame.current = requestAnimationFrame(updateProgress);

        analysis?.words.forEach((word, index) => {
          if (audioRef.current?.currentTime && audioRef.current?.currentTime * 1000 >= word.start && audioRef.current?.currentTime * 1000 <= word.end) {
            onChangeWordIndex(index);
          }
        });

        if (isNaN(duration || NaN) || (audioRef.current?.currentTime || 0) < (duration || 0)) {
          setCurrentTime(audioRef.current?.currentTime || 0);
        } else {
          setIsPlaying(false);
          onChangeWordIndex(-1);
          onFinish();
        }
      };

      if (!animationFrame.current) {
        updateProgress();
      }

      return () => {
        if (animationFrame.current) {
          cancelAnimationFrame(animationFrame.current);

          animationFrame.current = null;
        }
      };
    }, [analysis]);

    useEffect(() => {
      if (ref) {
        // @ts-ignore
        ref.current = {
          play: () => {
            audioRef.current?.play();
            setIsPlaying(true);
          },
          pause: () => {
            audioRef.current?.pause();
            setIsPlaying(false);
          },
          setCurrentTime: (time: number) => {
            if (audioRef.current) {
              audioRef.current.currentTime = time;
            }
          },
        };
      }
    }, []);

    return (
      <View className={clsx("flex items-center justify-between h-16", size === "small" ? "" : "px-2 py-1", className)}>
        {isCollapsable && (
          <Button
            type="basic"
            className="flex items-center justify-center min-w-8 !w-8 !h-12 !rounded-full"
            onClick={onClickShowProgressBar}
          >
            <DoubleArrowRounded className={clsx("text-gray-500 transition-all duration-1000 transform", showProgressBar ? "rotate-0" : "rotate-180")} />
          </Button>
        )}
        <Button
          className={clsx("flex items-center justify-center min-w-12 !w-12 !h-12 !rounded-full mr-2", size === "small" ? "!min-w-8 !w-8 !h-8" : "")}
          onClick={onClickPlayInternal}
        >
          {isPlaying ? <Pause className={size === "small" ? "!h-4 !w-4" : ""} /> : <PlayArrow className={size === "small" ? "!h-4 !w-4" : ""} />}
        </Button>
        <Button
          className={clsx("flex items-center justify-center min-w-12 !w-12 !h-12 !rounded-full mr-2", size === "small" ? "!min-w-8 !w-8 !h-8" : "")}
          onClick={onClickMuteInternal}
        >
          {isMuted ? <VolumeOff className={size === "small" ? "!h-4 !w-4" : ""} /> : <VolumeUp className={size === "small" ? "!h-4 !w-4" : ""} />}
        </Button>
        <View
          ref={progressBarRef}
          className={clsx(
            "relative flex transition-all duration-300 overflow-hidden h-full",
            showProgressBar ? "w-full min-w-[30vw] md:min-w-[15vw] mr-2" : "w-0 min-w-[0vw] mr-0",
            size === "small" ? "flex-row items-center gap-2" : "flex-col",
          )}
        >
          <Button
            type="basic"
            className={clsx(
              "!h-2 flex-grow bg-gray-300 rounded-full overflow-hidden active:!scale-100",
              size === "small" ? "" : "!absolute top-1/2 -translate-y-1/2 left-0 ",
            )}
            onMouseDown={onMouseDownProgress}
            onClick={onClickProgress}
          >
            <View
              className="absolute top-0 left-0 flex items-center justify-center h-full bg-gray-700 rounded-full"
              style={{
                width: `${(currentTime / (duration || 0)) * 100}%`,
              }}
            />
          </Button>
          <View className={clsx("flex items-center justify-end", size === "small" ? "" : "absolute bottom-0 right-0 ")}>
            <Text className="whitespace-nowrap">{!duration ? "--:-- / --:--" : `${secondsToTime(currentTime)} / ${secondsToTime(duration || 0)}`}</Text>
          </View>
        </View>
        {audioUrl && (
          <audio
            ref={audioRef}
            src={audioUrl}
            onLoadedMetadata={() => setDuration(audioRef.current?.duration || 0)}
            onEnded={() => setIsPlaying(false)}
          />
        )}
      </View>
    );
  },
);
