import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "../redux";
import ReactFlagsSelect from "react-flags-select";
import { COUNTRY_TO_LANGUAGE_CODES, LANGUAGE_TO_COUNTRY_CODES } from "../constants/language";
import { sessionActions } from "../redux/reducers/sessionSlice";

const MENU_ITEMS = [
  {
    title: "Audio Stories",
    path: "/admin/audioStories",
  },
  {
    title: "Audio Story Playlists",
    path: "/admin/audioStoryPlaylists",
  },
  {
    title: "Stories",
    path: "/admin/stories",
  },
  {
    title: "Story Sets",
    path: "/admin/storySets",
  },
  {
    title: "Themes",
    path: "/admin/themes",
  },
  {
    title: "Worlds",
    path: "/admin/worlds",
  },
  {
    title: "Characters",
    path: "/admin/characters",
  },
  {
    title: "Voices",
    path: "/admin/voices",
  },
  {
    title: "Users",
    path: "/admin/users",
  },
  {
    title: "Accounts",
    path: "/admin/accounts",
  },
  {
    title: "Account Readers",
    path: "/admin/accountReaders",
  },
  {
    title: "Account Contributors",
    path: "/admin/accountContributors",
  },
  {
    title: "Story Builder",
    path: "/admin/storyBuilder",
  },
];

type AdminLayoutProps = {
  className?: string;
};

export const AdminLayout = ({ className }: AdminLayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.session.user);
  const languageCode = useAppSelector((state) => state.session.languageCode);

  const onClickMenuItemHandler = (path: string) => () => {
    navigate(path);
  };

  const onSelectCountry = (countryCode: string) => {
    dispatch(sessionActions.setLanguage(COUNTRY_TO_LANGUAGE_CODES[countryCode]));
  };

  useEffect(() => {
    if (!user) {
      navigate(`/signin?redirect=${pathname}`);
    }

    if (user && !user.isAdmin) {
      navigate("/");
    }
  }, [user]);

  return user ? (
    <div className={clsx(className, "flex flex-row items-center justify-start w-full h-full overflow-hidden bg-white text-gray-900 admin-layout")}>
      <div className="flex flex-col items-center justify-between w-[200px] min-w-[200px] h-full bg-gray-200">
        <div className="flex flex-col items-center w-full h-full">
          {MENU_ITEMS.map((item) => (
            <div
              key={item.title}
              className={clsx("flex items-center justify-start w-full p-4 cursor-pointer text-gray-900", pathname === item.path ? "bg-gray-300" : "")}
              onClick={onClickMenuItemHandler(item.path)}
            >
              <span>{item.title}</span>
            </div>
          ))}
        </div>
        <ReactFlagsSelect
          selectButtonClassName="mt-1 w-full h-full !border-none"
          selected={LANGUAGE_TO_COUNTRY_CODES[languageCode || "en-us"]}
          onSelect={onSelectCountry}
          countries={Object.keys(COUNTRY_TO_LANGUAGE_CODES)}
          showSelectedLabel={false}
          showSecondarySelectedLabel={false}
          showOptionLabel={false}
          showSecondaryOptionLabel={false}
          rfsKey="language"
        />
      </div>
      <Outlet />
    </div>
  ) : (
    <div className={className} />
  );
};
