import { RestList } from "../components/RestList";
import { useEntity } from "../hooks/useEntity";
import { StoryTemplateSet } from "../redux/reducers/storyTemplateSetSlice";
import { StoryTemplate } from "../redux/reducers/storyTemplateSlice";

type AdminStoryTemplateSetsPageProps = {
  className?: string;
};

export const AdminStoryTemplateSetsPage = ({ className }: AdminStoryTemplateSetsPageProps) => {
  const { entities: storyTemplateEntities } = useEntity<StoryTemplate>("storyTemplate");

  const fields = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
    },
    {
      name: "storyTemplateIds",
      label: "Story Templates",
      type: "multiselect",
      options: Object.values(storyTemplateEntities).map((storyTemplate) => ({
        label: storyTemplate.name,
        value: storyTemplate.id,
      })),
      visible: false,
    },
    {
      name: "data",
      label: "Data",
      type: "json",
      visible: false,
    },
  ];

  return (
    <RestList<StoryTemplateSet>
      className={className}
      entityName="storyTemplateSet"
      fields={fields}
      sort={(ids, entities) => [...ids].sort((a, b) => entities[a]?.name.localeCompare(entities[b]?.name))}
    />
  );
};
